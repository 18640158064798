import React, { useState, useEffect, useRef } from 'react';
// import { Icon } from "primereact/icon";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { ProjectService } from '../service/ProjectService';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';

const Project = () => {
    let emptyProject = {
        project_id: null,
        name: '',
        customer_id: 0,
        address: '',
        field_engineer: '',
        image_data: null, // Add the image property
        status: 0,
        created_Date: new Date(),
        closed_Date: null,
    };

    const [projects, setProjects] = useState([]);
    const [projectDialog, setProjectDialog] = useState(false);
    const [deleteProjectDialog, setDeleteProjectDialog] = useState(false);
    const [deleteProjectsDialog, setDeleteProjectsDialog] = useState(false);
    const [project, setProject] = useState(emptyProject);
    const [selectedProjects, setSelectedProjects] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [status, setStatus] = useState(0);
    const [createdDate, setCreatedDate] = useState(new Date());
    const [closedDate, setClosedDate] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const navigate = useNavigate();


    const statusOptions = [
        {label: 'Open', value: 0},
        {label: 'Close', value: 1},
    ];

    useEffect(() => {
        const projectService = new ProjectService();
        // projectService.getProjects().then((response) => console.log(response.data));
        // projectService.getProjects().then((data) => setProjects(data));
        projectService.getProjects().then((response) => {
            setProjects(response.data);
        });
      
    }, []);

 
    const openNew = () => {
        setProject(emptyProject);
        setCreatedDate(new Date(project.created_Date));
        setClosedDate(new Date(project.closed_Date));
        setSubmitted(false);
        setProjectDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setProjectDialog(false);
    };

    const hideDeleteProjectDialog = () => {
        setDeleteProjectDialog(false);
    };

    const hideDeleteProjectsDialog = () => {
        setDeleteProjectsDialog(false);
    };



    const saveProject = () => {
        setSubmitted(true);
        project.customer_id=localStorage.getItem('customerId');
        project.created_Date=createdDate;
        project.closed_Date=closedDate;
        // if (project.name.trim() && project.image) { // Check if an image is selected+
        if (validateRecord(project)) {
            // let _projects = [...projects];
            // let _project = { ...project };
            const projectService = new ProjectService();
            // const isNewProject = !project.project_id;
            if (project.project_id==null) {
                // project.project_id = createId();
                
                projectService
                    .addProject(project)
                    .then((response) => {
                        // Handle the response for a new subscriber
                        // setProjects([...projects, response.data]);
                        setProjects([...projects, project]);
                        toast.current.show({
                            severity: 'success',
                            summary: 'Successful',
                            detail: 'Project Created',
                            life: 3000,
                        });
                        setProjectDialog(false);
                        setProject(emptyProject);
                    })
                    .catch((error) => {
                        console.error('Error saving new Project:', error);
                    });
            } else {

                projectService
                    .updateProject(project, project.project_id)
                    .then((response) => {
                        // Handle the response for an updated subscriber
                        const updatedProjects = projects.map((s) =>
                            s.project_id === response.data.project_id ? project : s
                        );
                        setProjects(updatedProjects);
                        toast.current.show({
                            severity: 'success',
                            summary: 'Successful',
                            detail: 'Project Updated',
                            life: 3000,
                        });
                        setProjectDialog(false);
                        setProject(emptyProject);
                    })
                    .catch((error) => {
                        console.error('Error updating Project:', error);
                    });
            }
        }
    };

    const validateRecord = (record) => {
        // Check if the subscription object is not null
        return record !== null && record !== undefined;
    };
    
    const editProject= (project) => {
        setProject({ ...project });
        setCreatedDate(new Date(project.created_Date));
        setClosedDate(new Date(project.closed_Date));
        setProjectDialog(true);
    };

    const confirmDeleteProject = (project) => {
        setProject(project);
        setDeleteProjectDialog(true);
    };


    const deleteProject = () => {
        const projectService = new ProjectService();
        projectService
            .deleteProject(project.project_id)
            .then(() => {
                // Delete the project from the local state
                let updatedProjects = projects.filter((val) => val.project_id !== project.project_id);
                setProjects(updatedProjects);
                setDeleteProjectDialog(false);
                setProject(emptyProject);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Project Deleted', life: 3000 });
            })
            .catch((error) => {
                console.error('Error deleting Project:', error);
                // Handle any error in the API call
            });

    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < projects.length; i++) {
            if (projects[i].project_id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                {rowData.image_data ? (
                    <img src={`data:image/png;base64,${rowData.image_data}`} alt="Project Image" className="shadow-2" width="100" />
                ) : (
                    'No Image'
                )}
            </>
        );
    };
    

    const formatDate = (value) => {
        if (value) {
            const date = new Date(value); // Convert string to Date object
            return date.toLocaleDateString('en-US', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
        }
        return ''; // Return an empty string if value is not defined
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const confirmDeleteSelected = () => {
        setDeleteProjectsDialog(true);
    };

    const deleteSelectedProjects = () => {
        let _projects = projects.filter((val) => !selectedProjects.includes(val));
        setProjects(_projects);
        setDeleteProjectsDialog(false);
        setSelectedProjects(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Projects Deleted', life: 3000 });
    };



    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _project = { ...project };
        _project[`${name}`] = val;

        if (name === 'status') {
            setStatus(val);
        } else if (name === 'created_Date') {
            setCreatedDate(val);
        } else if (name === 'closed_Date') {
            setClosedDate(val);
        }
        setProject(_project);
    };


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProjects || !selectedProjects.length} />
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" />
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    const idBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">ID</span>
                {rowData.project_id}
            </>
        );
    };
    
    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Project Name</span>
                {rowData.name}
            </>
        );
    };
    const custBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Address</span>
                {rowData.address}
            </>
        );
    };

    const fieldEngBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Field Engineer</span>
                {rowData.field_engineer}
            </>
        );
    };

    const statusBodyTemplate = (rowData) => {
        
        const iconClassName = classNames("pi", {
            "pi-circle-on": rowData.status === 0,
            "pi-check": rowData.status === 1
        });
    
        return (
        <>
            <span className="p-column-title {iconClassName}">Status</span>
            {rowData.status === 0 ? "Open" : "Close"}
        </>
        );    
    };
    
    const createdDateBodyTemplate = (rowData) => {
        const formattedDate = formatDate(rowData.created_Date);
        
        return (
            <>
            <span className="p-column-title">Created Date</span>
            {formattedDate}
            </>
        );
    };

    const closedDateBodyTemplate = (rowData) => {
        const formattedDate = formatDate(rowData.closed_date);
        
        return (
            <>
            <span className="p-column-title">Closed Date</span>
            {formattedDate}
            </>
        );
    };
    const actionBodyTemplate = (rowData) => {
        const buttonGroupStyle = {
            display: 'flex',      // Make the buttons display in a row
            justifyContent: 'space-between', // Distribute space between buttons
        };
    
        const buttonStyle = {
            flex: '1',            // Allow buttons to grow and share available space
            marginRight: '2px',   // Adjust spacing between buttons
        };
        return (
            <div className="actions" style={buttonGroupStyle}>
                <Button 
                    icon="pi pi-eye" 
                    className="p-button-rounded p-button-success" 
                    style={buttonStyle}
                    onClick={() => navigate(`/pages/dashboardProject/${rowData.project_id}`)}/>
                <Button 
                    icon="pi pi-pencil" 
                    className="p-button-rounded p-button-success" 
                    style={buttonStyle}
                    onClick={() => editProject(rowData)} />
                <Button 
                    icon="pi pi-trash" 
                    className="p-button-rounded p-button-warning" 
                    style={buttonStyle}
                    onClick={() => confirmDeleteProject(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Projects</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const projectDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProject} />
        </>
    );
    const deleteProjectDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProjectDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProject} />
        </>
    );
    const deleteProjectsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProjectsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProjects} />
        </>
    );

    const onImageSelect = (event) => {
        const file = event.files[0]; // Assuming you allow only one image to be uploaded
        if (file) {

            // Optionally, display a preview of the selected image:
            const reader = new FileReader();
            reader.onload = (e) => {
                const imageDataUrl = e.target.result;

                // Encode the image data as Base64
                const base64Image = imageDataUrl.split(',')[1]; // Extract the Base64 part
    
                setProject({ ...project, image_data: base64Image });
    
                // Optionally, display a preview of the selected image:
                setPreviewImage(imageDataUrl);

            };
            reader.readAsDataURL(file);
        }
    };
    
    
    

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={projects}
                        selection={selectedProjects}
                        onSelectionChange={(e) => setSelectedProjects(e.value)}
                        dataKey="project_id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} projects"
                        globalFilter={globalFilter}
                        emptyMessage="No projects found."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="project_id" header="ID" body={idBodyTemplate}></Column>
                        <Column field="name" header="Project Name" sortable body={nameBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="address" header="Address" sortable body={custBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        
                        <Column field="field_engineer" header="Stakeholder" sortable body={fieldEngBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column header="Image" body={imageBodyTemplate} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                        <Column field="status" header="status" body={statusBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '8rem' }}></Column>
                        <Column field="created_Date" header="Created Date" sortable body={createdDateBodyTemplate} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                        <Column field="closed_Date" header="Closed Date" body={closedDateBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={projectDialog} style={{ width: '450px' }} header="Project Details" modal className="p-fluid" footer={projectDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="name">Project Name</label>
                            <InputText id="name" value={project.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !project.name })} />
                            {submitted && !project.name && <small className="p-invalid">Name is required.</small>}
                        </div>
                        
                        <div className="field">
                            <label htmlFor="field_engineer">Stakeholder</label>
                            <InputText id="field_engineer" value={project.field_engineer} onChange={(e) => onInputChange(e, 'field_engineer')} required  cols={20} />
                        </div>
                        <div className="field">
                            <label htmlFor="address">Address</label>
                            <InputTextarea id="address" value={project.address} onChange={(e) => onInputChange(e, 'address')} required rows={3} cols={20} />
                        </div>
                        <div className="field">
                            <label htmlFor="status">Status</label>
                            <Dropdown id="status" value={status} options={statusOptions} onChange={(e) => onInputChange(e, 'status')} />
                        </div>
                        <div className="field">
                            <label htmlFor="created_Date">Created Date</label>
                            <Calendar id="created_Date" value={createdDate} onChange={(e) => onInputChange(e, 'created_Date')} showIcon={true} dateFormat="dd/mm/yy" />
                        </div>
                        <div className="field">
                            <label htmlFor="image">Image</label>
                            <FileUpload
                                name="demo"
                                chooseLabel="Upload"
                                mode="basic"
                                auto
                                accept="image/jpeg"  // Accept only JPEG images
                                onSelect={(e) => onImageSelect(e)}
                            />
                            {previewImage && (
                                <div>
                                    <label>Selected Image Preview:</label>
                                    <img src={previewImage} alt="Selected Image" width="150" />
                                </div>
                            )}
                        </div>


                    </Dialog>

                    <Dialog visible={deleteProjectDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProjectDialogFooter} onHide={hideDeleteProjectDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {project && (
                                <span>
                                    Are you sure you want to delete <b>{project.name}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteProjectsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProjectsDialogFooter} onHide={hideDeleteProjectsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {project && <span>Are you sure you want to delete the selected projects?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default Project;
