import React, { useState, useEffect, useRef } from 'react';
import { Calendar, Dropdown, DataTable, Column, Toast, Button, Dialog, InputText, InputTextarea } from 'primereact';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { classNames } from 'primereact/utils';
import { InputNumber } from 'primereact/inputnumber';
import { PaymentTransactionService } from '../service/PaymentTransactionService';
import { PaymentMethodService } from '../service/PaymentMethodService';
import { CustomerService } from '../service/CustomerService';

const PaymentTransaction = () => {
    let emptyPaymentTransaction = {
        transaction_id:null,
        stripe_transaction_id:'',
        payment_method_id: null,
        amount: 0.0,
        currency: '',
        receipt_url: '',
        status: 0,
        created_at: new Date(),
        // ... other fields as per your model
    };

    const [paymentTransactions, setPaymentTransactions] = useState([]);
    const [paymentTransactionDialog, setPaymentTransactionDialog] = useState(false);
    const [deletePaymentTransactionDialog, setDeletePaymentTransactionDialog] = useState(false);
    const [deletePaymentTransactionsDialog, setDeletePaymentTransactionsDialog] = useState(false);
    const [paymentTransaction, setPaymentTransaction] = useState(emptyPaymentTransaction);
    const [selectedPaymentTransactions, setSelectedPaymentTransactions] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [status, setStatus] = useState(0);
    const [createdDate, setCreatedDate] = useState(new Date());


    const [paymentMethods, setPaymentMethods] = useState([]);
    const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(null);
    

    const statusOptions = [
        {label: 'Open', value: 0},
        {label: 'Close', value: 1},
    ];

    useEffect(() => {
        const paymentTransactionService = new PaymentTransactionService();
        paymentTransactionService.getPaymentTransactions().then((response) => {
            setPaymentTransactions(response.data);
        });
        const paymentMethodService = new PaymentMethodService();
        paymentMethodService.getPaymentMethods().then((response) => {
            setPaymentMethods(response.data);
        });
    }, []);

    const openNew = () => {
        setPaymentTransaction(emptyPaymentTransaction);
        
        setSelectedPaymentMethodId(paymentTransaction.type_id)
        setCreatedDate(new Date(paymentTransaction.created_at));
        setSubmitted(false);
        setPaymentTransactionDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setPaymentTransactionDialog(false);
    };

    const hideDeletePaymentTransactionDialog = () => {
        setDeletePaymentTransactionDialog(false);
    };

    const hideDeletePaymentTransactionsDialog = () => {
        setDeletePaymentTransactionsDialog(false);
    };

    
    const savePaymentTransaction = () => {
        setSubmitted(true);

        
        paymentTransaction.payment_method_id = selectedPaymentMethodId;
        paymentTransaction.created_at=createdDate;

     
            
        if (validateRecord(paymentTransaction)) {

            
            const paymentTransactionService = new PaymentTransactionService();
            // const isNewPaymentTransaction = !paymentTransaction.transaction_id;
            
            if (paymentTransaction.transaction_id==null) {
                // device.device_id = createId();
                // device.customer_id=localStorage.getItem('customerId');
                paymentTransactionService
                    .addPaymentTransaction(paymentTransaction)
                    .then((response) => {
                        // Handle the response for a new paymentTransaction
                        setPaymentTransactions([...paymentTransactions, paymentTransaction]);
                        toast.current.show({
                            severity: 'success',
                            summary: 'Successful',
                            detail: 'PaymentTransaction Created',
                            life: 3000,
                        });
                        setPaymentTransactionDialog(false);
                        setPaymentTransaction(emptyPaymentTransaction);
                    })
                    .catch((error) => {
                        console.error('Error saving new PaymentTransaction:', error);
                    });
            } else {

                paymentTransactionService
                    .updatePaymentTransaction(paymentTransaction, paymentTransaction.transaction_id)
                    .then((response) => {
                        // Handle the response for an updated subscriber
                        const updatedPaymentTransactions = paymentTransactions.map((s) =>
                            s.transaction_id === response.data.transaction_id ? paymentTransaction : s
                        );
                        setPaymentTransactions(updatedPaymentTransactions);
                        toast.current.show({
                            severity: 'success',
                            summary: 'Successful',
                            detail: 'PaymentTransaction Updated',
                            life: 3000,
                        });
                        setPaymentTransactionDialog(false);
                        setPaymentTransaction(emptyPaymentTransaction);
                    })
                    .catch((error) => {
                        console.error('Error updating PaymentTransaction:', error);
                    });
            }
        }
    };


    
    const validateRecord = (paymentTransaction) => {
        // Check if the paymentTransaction object is not null
        return paymentTransaction !== null && paymentTransaction !== undefined;
    };

    
    const editPaymentTransaction= (paymentTransaction) => {
        setPaymentTransaction({ ...paymentTransaction });
        
        setSelectedPaymentMethodId(paymentTransaction.type_id)
        setCreatedDate(new Date(paymentTransaction.created_at));
        setPaymentTransactionDialog(true);
    };

    const confirmDeletePaymentTransaction = (paymentTransaction) => {
        setPaymentTransaction(paymentTransaction);
        setDeletePaymentTransactionDialog(true);
    };


    const deletePaymentTransaction = () => {
        const paymentTransactionService = new PaymentTransactionService();
        paymentTransactionService
            .deletePaymentTransaction(paymentTransaction.transaction_id)
            .then(() => {
                // Delete the paymentTransaction from the local state
                let updatedPaymentTransactions = paymentTransactions.filter((val) => val.transaction_id !== paymentTransaction.transaction_id);
                setPaymentTransactions(updatedPaymentTransactions);
                setDeletePaymentTransactionDialog(false);
                setPaymentTransaction(emptyPaymentTransaction);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'PaymentTransaction Deleted', life: 3000 });
            })
            .catch((error) => {
                console.error('Error deleting PaymentTransaction:', error);
                // Handle any error in the API call
            });

    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < paymentTransaction.length; i++) {
            if (paymentTransaction[i].transaction_id === id) {
                index = i;
                break;
            }
        }

        return index;
    };


    
    const formatDate = (value) => {
        if (value) {
            const date = new Date(value); // Convert string to Date object
            return date.toLocaleDateString('en-US', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
        }
        return ''; // Return an empty string if value is not defined
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const confirmDeleteSelected = () => {
        setDeletePaymentTransactionsDialog(true);
    };

    const deleteSelectedPaymentTransactions = () => {
        let _paymentTransactions = paymentTransactions.filter((val) => !selectedPaymentTransactions.includes(val));
        setPaymentTransactions(_paymentTransactions);
        setDeletePaymentTransactionsDialog(false);
        setSelectedPaymentTransactions(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'PaymentTransactions Deleted', life: 3000 });
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _paymentTransaction = { ...paymentTransaction };
        _paymentTransaction[`${name}`] = val;

        if (name === 'status') {
            setStatus(val);
        } else if (name === 'created_Date') {
            setCreatedDate(val);
        }
        setPaymentTransaction(_paymentTransaction);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedPaymentTransactions || !selectedPaymentTransactions.length} />
                </div>
            </React.Fragment>
        );

    };
    

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" />
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">PaymentTransaction Name</span>
                {rowData.name}
            </>
        );
    };

    // DataTable columns
    const idBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">ID</span>
                {rowData.transaction_id}
            </>
        );
        
    };
    const stripeidBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Stripe Id</span>
                {rowData.stripe_payment_method_id}
            </>
        );
    };

    const paymentMethodBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Payment Method</span>
                {rowData.payment_method_id && paymentMethods.find((customer) => customer.customer_id === rowData.customer_id)?.name}
              
            </>
        );
    };

    const paymentTransactionTypeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">PaymentMethod Name</span>
                {rowData.type_id && paymentMethods.find((paymentMethods) => paymentMethods.payment_method_id === rowData.payment_method_id)?.type_name}
            </>
        );
    };

    const typeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Type ID</span>
                {rowData.type_id}
            </>
        );
    };
    const statusBodyTemplate = (rowData) => {
        
        const iconClassName = classNames("pi", {
            "pi-circle-on": rowData.status === 0,
            "pi-check": rowData.status === 1
        });
    
        return (
        <>
            <span className="p-column-title {iconClassName}">Status</span>
            {rowData.status === 0 ? "Open" : "Close"}
        </>
        );    
    };
    
    const startDateBodyTemplate = (rowData) => {
        const formattedDate = formatDate(rowData.start_date);
        
        return (
            <>
            <span className="p-column-title">Start Date</span>
            {formattedDate}
            </>
        );
    };

    const amountBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Amount</span>
                {rowData.amount}
            </>
        );
    };

    const currencyBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Currency</span>
                {rowData.currency}
            </>
        );
    };

    const receiptBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Receipt Url</span>
                {rowData.receipt_url}
            </>
        );
    };

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _paymentTransaction = { ...paymentTransaction };
        _paymentTransaction[`${name}`] = val;

        setPaymentTransaction(_paymentTransaction);
    };

    const actionBodyTemplate = (rowData) => {
        const buttonGroupStyle = {
            display: 'flex',      // Make the buttons display in a row
            justifyContent: 'space-between', // Distribute space between buttons
        };
    
        const buttonStyle = {
            flex: '1',            // Allow buttons to grow and share available space
            marginRight: '2px',   // Adjust spacing between buttons
        };
        return (
            <div className="actions" style={buttonGroupStyle}>
                <Button 
                    icon="pi pi-pencil" 
                    className="p-button-rounded p-button-success" 
                    style={buttonStyle}
                    onClick={() => editPaymentTransaction(rowData)} />
                <Button 
                    icon="pi pi-trash" 
                    className="p-button-rounded p-button-warning" 
                    style={buttonStyle}
                    onClick={() => confirmDeletePaymentTransaction(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Payment Transactions</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );


    const paymentTransactionDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={savePaymentTransaction} />
        </>
    );

    const deletePaymentTransactionDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeletePaymentTransactionDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deletePaymentTransaction} />
        </>
    );

    const deletePaymentTransactionsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeletePaymentTransactionsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedPaymentTransactions} />
        </>
    );
    

    // Table columns and templates

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    <DataTable 
                        ref={dt}
                        value={paymentTransactions} 
                        selection={selectedPaymentTransactions} 
                        onSelectionChange={(e) => setSelectedPaymentTransactions(e.value)}
                        dataKey="transaction_id" 
                        paginator 
                        rows={10} 
                        rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} paymentTransactions"
                        globalFilter={globalFilter}
                        emptyMessage="No PaymentTransaction found."
                        header={header}
                        >
                            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>

                            <Column field="transaction_id" header="ID" body={idBodyTemplate}></Column>
                            <Column field="stripe_transaction_id" header="Stripe ID" body={stripeidBodyTemplate} headerStyle={{ width: '30%', minWidth: '10rem' }}></Column>
                            <Column field="payment_method_id" header="Payment Method" body={paymentMethodBodyTemplate} headerStyle={{ width: '30%', minWidth: '10rem' }}></Column>
                            <Column field="amount" header="amount" sortable body={amountBodyTemplate} headerStyle={{ width: '15%', minWidth: '10rem' }}></Column>
                            <Column field="currency" header="Currency" sortable body={currencyBodyTemplate} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                            <Column field="receipt_url" header="Receipt URL" sortable body={receiptBodyTemplate} headerStyle={{ width: '15%', minWidth: '10rem' }}></Column>
                            
                            <Column field="status" header="status" body={statusBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '8rem' }}></Column>
                            <Column field="created_at" header="Created Date" sortable body={startDateBodyTemplate} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                            
                            <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>

            {/* PaymentTransaction Dialog */}
            <Dialog visible={paymentTransactionDialog} style={{ width: '450px' }} header="PaymentTransaction Details" modal className="p-fluid" footer={paymentTransactionDialogFooter} onHide={hideDialog}>
                
                
                <div className="p-field">
                    <label htmlFor="stripeId">StripeID</label>
                    <InputText id="stripeId" value={paymentTransaction.stripe_transaction_id} onChange={(e) => onInputChange(e, 'stripe_transaction_id')} />
                </div>
                <div className="p-field">
                    <label htmlFor="paymentMethod">Payment Method</label>
                    <Dropdown
                        id="paymentMethod"
                        value={selectedPaymentMethodId}
                        options={paymentMethods.map(paymentMethod => ({ label: paymentMethod.type_name, value: paymentMethod.payment_method_id }))}
                        onChange={(e) => setSelectedPaymentMethodId(e.value)}
                        placeholder="Select a Method"
                    />
                </div>
                <div className="field">
                    <label htmlFor="amount">amount</label>
                    <InputNumber  id="amount" value={paymentTransaction.amount} onChange={(e) => onInputNumberChange(e, 'amount')} required  cols={20} />
                </div>
                <div className="field">
                    <label htmlFor="currency">Currency</label>
                    <InputText id="currency" value={paymentTransaction.currency} onChange={(e) => onInputChange(e, 'currency')} required  cols={20} />
                </div>
                <div className="field">
                    <label htmlFor="receiptUrl">Receipt_url</label>
                    <InputText id="receiptUrl" value={paymentTransaction.receipt_url} onChange={(e) => onInputChange(e, 'receipt_url')} required  cols={20} />
                </div>
                <div className="p-field">
                    <label htmlFor="createdDate">Created</label>
                    <Calendar id="createdDate" value={createdDate} onChange={(e) => setCreatedDate(e.value)} showIcon />
                </div>
                
                <div className="p-field">
                    <label htmlFor="status">Status</label>
                    <Dropdown id="status" value={status} options={statusOptions} onChange={(e) => onInputChange(e, 'status')}/>
                </div>
            </Dialog>

            {/* Delete PaymentTransaction Dialog */}
            <Dialog visible={deletePaymentTransactionDialog} style={{ width: '450px' }} header="Confirm" modal footer={deletePaymentTransactionDialogFooter} onHide={hideDeletePaymentTransactionDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {paymentTransaction && <span>Are you sure you want to delete the paymentTransaction <b>{paymentTransaction.transaction_id}</b>?</span>}
                </div>
            </Dialog>

            <Dialog visible={deletePaymentTransactionsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deletePaymentTransactionsDialogFooter} onHide={hideDeletePaymentTransactionsDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {paymentTransaction && <span>Are you sure you want to delete the selected paymentTransactions?</span>}
                </div>
            </Dialog>
        </div>
    );
};

export default PaymentTransaction;