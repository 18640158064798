import axios from 'axios';


// // Define the base URLs for the local and remote servers
const LOCAL_API_BASE_URL = 'http://localhost:5001';
const REMOTE_API_BASE_URL = 'https://api.mysensorhub.com';

// Define the server variable ('local' or 'remote')
const server = 'remote';  // Change this to 'remote' when needed

// Define the base URL based on the server variable
const API_BASE_URL = server === 'local' ? LOCAL_API_BASE_URL : REMOTE_API_BASE_URL;


const apiClient = axios.create({
  baseURL: API_BASE_URL
});

// Response interceptor
apiClient.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // Handle token expiration (e.g., redirect to login, refresh token, etc.)
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export class GaugeService {

  // Helper function to get headers with Authorization token
  getAuthHeaders() {
    const accessToken = localStorage.getItem('accessToken');
    return {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    };
  }

  getGauges() {
    const customerId = localStorage.getItem('customerId');  // Retrieve customer_id from local storage
    const url = `/gauges/${customerId}`;
    return apiClient.get(url, this.getAuthHeaders());
  }

  addGauge(gauge) {
    return apiClient.post('/gauges', gauge, this.getAuthHeaders());
  }

  updateGauge(gauge, gauge_id) {
    return apiClient.put(`/gauges/${gauge_id}`, gauge, this.getAuthHeaders());
  }

  deleteGauge(gauge_id) {
    return apiClient.delete(`/gauge/${gauge_id}`, this.getAuthHeaders());
  }

  getGaugeById(gauge_id) {
    return apiClient.get(`/gauge/${gauge_id}`, this.getAuthHeaders());
  }

  getDevices() {
    const customerId = localStorage.getItem('customerId');  // Retrieve customer_id from local storage
    const url = `/devices2/${customerId}`;
    return apiClient.get(url, this.getAuthHeaders());
  }

  getGaugesForDevice(device_id) {
    const url = `/gauges/device/${device_id}`;
    return apiClient.get(url, this.getAuthHeaders());
  }

}
