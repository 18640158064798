import axios from 'axios';

// // Define the base URLs for the local and remote servers
const LOCAL_API_BASE_URL = 'http://localhost:5001';
const REMOTE_API_BASE_URL = 'https://api.mysensorhub.com';

// Define the server variable ('local' or 'remote')
const server = 'remote';  // Change this to 'remote' when needed

// Define the base URL based on the server variable
const API_BASE_URL = server === 'local' ? LOCAL_API_BASE_URL : REMOTE_API_BASE_URL;

const apiClient = axios.create({
    baseURL: API_BASE_URL,
});

// Response interceptor
apiClient.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            // Handle token expiration (e.g., redirect to login, refresh token, etc.)
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

export class PaymentTransactionService {

    // Helper function to get headers with Authorization token
    getAuthHeaders() {
        const accessToken = localStorage.getItem('accessToken');
        return {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        };
    }

    getPaymentTransactions() {
        return apiClient.get('/payment-transactions', this.getAuthHeaders());
    }

    getPaymentTransactionById(transactionId) {
        return apiClient.get(`/payment-transactions/${transactionId}`, this.getAuthHeaders());
    }

    addPaymentTransaction(transactionData) {
        return apiClient.post('/payment-transactions', transactionData, this.getAuthHeaders());
    }

    updatePaymentTransaction(transactionId, transactionData) {
        return apiClient.put(`/payment-transactions/${transactionId}`, transactionData, this.getAuthHeaders());
    }

    deletePaymentTransaction(transactionId) {
        return apiClient.delete(`/payment-transactions/${transactionId}`, this.getAuthHeaders());
    }
}
