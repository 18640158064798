import React, { useState, useEffect } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { Divider } from 'primereact/divider';
import '../assets/theme/argos.css';



import { classNames } from 'primereact/utils';
import { SubscriptionTypeService } from '../service/SubscriptionTypeService'; // Ensure this service is implemented to fetch plans

const BuySubscription = () => {
    const [subscriptionTypes, setSubscriptionTypes] = useState([]);

    useEffect(() => {
      const subscriptionTypeService = new SubscriptionTypeService();
      subscriptionTypeService.getSubscriptionTypes().then(response => {
          // Sort the subscription types by type_id before setting them to state
          const sortedSubscriptionTypes = response.data.sort((a, b) => a.type_id - b.type_id);
          setSubscriptionTypes(sortedSubscriptionTypes);
      });
    }, []);

    const handleSubscriptionChange = (id, checked) => {
      setSubscriptionTypes(prevTypes =>
          prevTypes.map(type =>
              type.type_id === id ? { ...type, isYearly: checked } : type
          )
      );
    };

    const calculateFinalPrice = (type) => {
      let finalPrice = type.price;
      if (type.isYearly) {
          finalPrice = (type.price * 12) * (1 - type.discount / 100);
      }
      return finalPrice.toFixed(2);
    };

    // const subscriptionCards = subscriptionTypes.map((type, index) => (
    //     <div className="col-4" key={type.type_id}>
    //         <Card title={<span style={{ background: getColor(type.type_name), color: 'white', padding: '5px 10px', borderRadius: '4px' }}>{type.type_name.toUpperCase()}</span>} style={{ textAlign: 'center' }}>
    //             <h1>${type.price}</h1>
    //             <p>Days: {type.days}</p>
    //             <p>Max Gauges: {type.max_gauges}</p>
    //             <Button label="Buy Now" icon="pi pi-check" />
    //         </Card>
    //     </div>
    // ));
    function getColor(typeName) {
      switch (typeName.toLowerCase()) {
        case 'free plan':
          return '#00D1B2'; // Teal color
        case 'basic plan':
          return '#3273DC'; // Blue color
        case 'premium plan':
          return '#F14668'; // Pink color
        default:
          return '#DDDDDD'; // Default color
      }
    }

    const subscriptionCards = subscriptionTypes.map((type) => (
      <div className="col-4  card-wrapper" key={type.type_id}>
          <Card className="custom-card" title={<span style={{ background: getColor(type.type_name), color: 'white', padding: '5px 10px', borderRadius: '4px' }}>{type.type_name.toUpperCase()}</span>} style={{ textAlign: 'center' }}>
              <InputSwitch checked={!!type.isYearly} onChange={(e) => handleSubscriptionChange(type.type_id, e.value)} />
              <p>{type.isYearly ? 'Yearly' : 'Monthly'}</p>
              <h2>US$ {type.price} <span style={{ fontSize: '0.6em' }}>per month</span></h2>
              {type.isYearly && <p>{type.discount}% Discount</p>}
              <h1>US$ {calculateFinalPrice(type)}</h1>
              <Divider />
              <h2>Features:</h2>
              <ul>
                {type.description.split('\n').map((feature, idx) => (
                    <li key={idx} style={{ listStyleType: 'none', display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                        <i className="pi pi-check" style={{ marginRight: '5px',fontSize: '2rem', color: '#34A853' }}></i>
                        {feature}
                    </li>
                ))}
              </ul>
              <Button label="Buy Now" icon="pi pi-check" />
          </Card>
      </div>
    ));
    
   
    return (
      <div>
        <h2>Subscription Plans Available:</h2>
        {/* Use a container to apply flexbox styling */}
        <div className="grid p-grid" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
            {subscriptionCards}
        </div>
      </div>
    );
};

export default BuySubscription;
