import React, { useState, useEffect, useRef } from 'react';
// import { Icon } from "primereact/icon";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { DeviceService } from '../service/DeviceService';
import { v4 as uuidv4 } from 'uuid';

const Device = () => {
    let emptyDevice = {
        device_id: null,
        project_id:0,
        name: '',
        model: '',
        serial: '',
        address: '',
        latitude:0.0,
        longitude:0.0,
        phone: '',
        image_data: null, // Add the image property
        status: 0,
        created_Date: new Date(),
    };

    const [devices, setDevices] = useState([]);
    const [deviceDialog, setDeviceDialog] = useState(false);
    const [deleteDeviceDialog, setDeleteDeviceDialog] = useState(false);
    const [deleteDevicesDialog, setDeleteDevicesDialog] = useState(false);
    const [device, setDevice] = useState(emptyDevice);
    const [selectedDevices, setSelectedDevices] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [status, setStatus] = useState(0);
    const [createdDate, setCreatedDate] = useState(new Date());
    const [closedDate, setClosedDate] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);

    const [projects, setProjects] = useState([]); // Initialize with an empty array
    const [selectedProjectId, setSelectedProjectId] = useState(0); // Initialize with a default value or null
    


    const statusOptions = [
        {label: 'Active', value: 0},
        {label: 'Inactive', value: 1},
    ];

    useEffect(() => {
        const deviceService = new DeviceService();
        // deviceService.getDevices().then((response) => console.log(response.data));
        // deviceService.getDevices().then((data) => setDevices(data));
        deviceService.getDevices().then((response) => {
            setDevices(response.data);
        });

        // Fetch the list of projects and store them in the 'projects' state variable
        deviceService.getProjects().then((response) => {
            setProjects(response.data);
        });
      
    }, []);

    
    const openNew = () => {
        setDevice(emptyDevice);
        setSelectedProjectId(device.project_id); 
        setCreatedDate(new Date(device.created_Date));
        setSubmitted(false);
        setDeviceDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setDeviceDialog(false);
    };

    const hideDeleteDeviceDialog = () => {
        setDeleteDeviceDialog(false);
    };

    const hideDeleteDevicesDialog = () => {
        setDeleteDevicesDialog(false);
    };


    const saveDevice = () => {
        setSubmitted(true);
    
        device.project_id = selectedProjectId;
        device.created_Date=createdDate;

        // if (device.name.trim() && device.image) { // Check if an image is selected+
        if (validateRecord(device)) {
            // let _devices = [...devices];
            // let _device = { ...device };
            const deviceService = new DeviceService();
            // const isNewDevice = !device.device_id;
            if (device.device_id==null) {
                // device.device_id = createId();
                // device.customer_id=localStorage.getItem('customerId');
                deviceService
                    .addDevice(device)
                    .then((response) => {
                        // Handle the response for a new subscriber
                        // setDevices([...devices, response.data]);
                        setDevices([...devices, device]);
                        toast.current.show({
                            severity: 'success',
                            summary: 'Successful',
                            detail: 'Device Created',
                            life: 3000,
                        });
                        setDeviceDialog(false);
                        setDevice(emptyDevice);
                    })
                    .catch((error) => {
                        console.error('Error saving new Device:', error);
                    });
            } else {

                deviceService
                    .updateDevice(device, device.device_id)
                    .then((response) => {
                        // Handle the response for an updated subscriber
                        const updatedDevices = devices.map((s) =>
                            s.device_id === response.data.device_id ? device : s
                        );
                        setDevices(updatedDevices);
                        toast.current.show({
                            severity: 'success',
                            summary: 'Successful',
                            detail: 'Device Updated',
                            life: 3000,
                        });
                        setDeviceDialog(false);
                        setDevice(emptyDevice);
                    })
                    .catch((error) => {
                        console.error('Error updating Device:', error);
                    });
            }
        }
    };

   
    const validateRecord = (record) => {
        // Check if the subscription object is not null
        return record !== null && record !== undefined;
    };

    const editDevice= (device) => {
        setDevice({ ...device });
        setSelectedProjectId(device.project_id); 
        setCreatedDate(new Date(device.created_Date));
        setDeviceDialog(true);
    };

    const confirmDeleteDevice = (device) => {
        setDevice(device);
        setDeleteDeviceDialog(true);
    };

 
    const deleteDevice = () => {
        const deviceService = new DeviceService();
        deviceService
            .deleteDevice(device.device_id)
            .then(() => {
                // Delete the device from the local state
                let updatedDevices = devices.filter((val) => val.device_id !== device.device_id);
                setDevices(updatedDevices);
                setDeleteDeviceDialog(false);
                setDevice(emptyDevice);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Device Deleted', life: 3000 });
            })
            .catch((error) => {
                console.error('Error deleting Device:', error);
                // Handle any error in the API call
            });

    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < devices.length; i++) {
            if (devices[i].device_id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                {rowData.image_data ? (
                    <img src={`data:image/png;base64,${rowData.image_data}`} alt="Device Image" className="shadow-2" width="100" />
                ) : (
                    'No Image'
                )}
            </>
        );
    };
    

    const formatDate = (value) => {
        if (value) {
            return value.toLocaleDateString('en-US', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
        }
        return ''; // Return an empty string if value is not defined
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const confirmDeleteSelected = () => {
        setDeleteDevicesDialog(true);
    };

    const deleteSelectedDevices = () => {
        let _devices = devices.filter((val) => !selectedDevices.includes(val));
        setDevices(_devices);
        setDeleteDevicesDialog(false);
        setSelectedDevices(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Devices Deleted', life: 3000 });
    };


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _device = { ...device };
        _device[`${name}`] = val;

        if (name === 'status') {
            setStatus(val);
        } else if (name === 'created_date') {
            setCreatedDate(val);
        } else if (name === 'closed_date') {
            setClosedDate(val);
        }
        setDevice(_device);
    };

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _device = { ...device };
        _device[`${name}`] = val;

        setDevice(_device);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedDevices || !selectedDevices.length} />
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" />
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

  

    const idBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Id</span>
                {rowData.device_id}
            </>
        );
    };

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Device Name</span>
                {rowData.name}
            </>
        );
    };

    const portBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Port</span>
                {rowData.port}
            </>
        );
    };

    const mfrBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Manufacturer</span>
                {rowData.manufacturer}
            </>
        );
    };

    const modelBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Model</span>
                {rowData.model}
            </>
        );
    };
    const serialBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Serial</span>
                {rowData.serial}
            </>
        );
    };
    // Create a custom body template for the 'project_id' column
    const projectBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Project</span>
                {rowData.project_id && projects.find((project) => project.project_id === rowData.project_id)?.name}
            </>
        );
    };

    const latBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Latitude</span>
                {rowData.latitude}
            </>
        );
    };

    const lonBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Longitude</span>
                {rowData.longitude}
            </>
        );
    };
    
    const statusBodyTemplate = (rowData) => {
        // return (
        //   <>
        //     <span className="p-column-title">Status</span>
        //     {rowData.status === 0 ? "Open" : "Close"}
        //   </>
        // );
        const iconClassName = classNames("pi", {
            "pi-circle-on": rowData.status === 0,
            "pi-check": rowData.status === 1
        });
    
        return (
        <>
            <span className="p-column-title {iconClassName}">Status</span>
            {rowData.status === 0 ? "Active" : "Inactive"}
        </>
        );    
    };
    const createdDateBodyTemplate = (rowData) => {
        const formattedDate = formatDate(rowData.created_date);
        
        return (
            <>
            <span className="p-column-title">Created Date</span>
            {formattedDate}
            </>
        );
    };

   
    const actionBodyTemplate = (rowData) => {
        const buttonGroupStyle = {
            display: 'flex',      // Make the buttons display in a row
            justifyContent: 'space-between', // Distribute space between buttons
        };
    
        const buttonStyle = {
            flex: '1',            // Allow buttons to grow and share available space
            marginRight: '2px',   // Adjust spacing between buttons
        };
        return (
            <div className="actions" style={buttonGroupStyle}>
                <Button 
                    icon="pi pi-pencil" 
                    className="p-button-rounded p-button-success" 
                    style={buttonStyle}
                    onClick={() => editDevice(rowData)} />
                <Button 
                    icon="pi pi-trash" 
                    className="p-button-rounded p-button-warning" 
                    style={buttonStyle}
                    onClick={() => confirmDeleteDevice(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Devices</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const deviceDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveDevice} />
        </>
    );
    const deleteDeviceDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDeviceDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteDevice} />
        </>
    );
    const deleteDevicesDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDevicesDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedDevices} />
        </>
    );

    const onImageSelect = (event) => {
        const file = event.files[0]; // Assuming you allow only one image to be uploaded
        if (file) {

            // Optionally, display a preview of the selected image:
            const reader = new FileReader();
            reader.onload = (e) => {
                const imageDataUrl = e.target.result;

                // Encode the image data as Base64
                const base64Image = imageDataUrl.split(',')[1]; // Extract the Base64 part
    
                setDevice({ ...device, image_data: base64Image });
    
                // Optionally, display a preview of the selected image:
                setPreviewImage(imageDataUrl);

            };
            reader.readAsDataURL(file);
        }
    };
    
    
    

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={devices}
                        selection={selectedDevices}
                        onSelectionChange={(e) => setSelectedDevices(e.value)}
                        dataKey="device_id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} devices"
                        globalFilter={globalFilter}
                        emptyMessage="No devices found."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="device_id" header="ID" body={idBodyTemplate}></Column>
                        <Column field="project_id" header="Project" body={projectBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        {/* <Column field="project_id" header="Project ID" sortable body={nameBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column> */}
                        <Column field="name" header="Name" sortable body={nameBodyTemplate} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                        <Column field="model" header="Model" sortable body={modelBodyTemplate} headerStyle={{ width: '15%', minWidth: '10rem' }}></Column>
                        <Column field="serial" header="Serial" sortable body={serialBodyTemplate} headerStyle={{ width: '15%', minWidth: '10rem' }}></Column>
                        <Column header="Image" body={imageBodyTemplate} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                        <Column field="latitude" header="latitude" sortable body={latBodyTemplate} headerStyle={{ width: '15%', minWidth: '10rem' }}></Column>
                        <Column field="longitude" header="longitude" sortable body={lonBodyTemplate} headerStyle={{ width: '15%', minWidth: '10rem' }}></Column>
                        
                        <Column field="status" header="Status" body={statusBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '8rem' }}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={deviceDialog} style={{ width: '450px' }} header="Device Details" modal className="p-fluid" footer={deviceDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="projectId">Project</label>
                            <Dropdown
                                id="projectId"
                                value={selectedProjectId}
                                options={projects.map(project => ({ label: project.name, value: project.project_id }))}
                                onChange={(e) => setSelectedProjectId(e.value)}
                                placeholder="Select a Project"
                            />
                        </div>
                        <div className="field">
                            <label htmlFor="name">Device Name</label>
                            <InputText id="name" value={device.name} onChange={(e) => onInputChange(e, 'name')} required  cols={20} />
                        </div>
                        <div className="field">
                            <label htmlFor="model">Model</label>
                            <InputText id="model" value={device.model} onChange={(e) => onInputChange(e, 'model')} required  cols={20} />
                        </div>
                        <div className="field">
                            <label htmlFor="serial">serial</label>
                            <InputText id="serial" value={device.serial} onChange={(e) => onInputChange(e, 'serial')} required  cols={20} />
                        </div>
                        <div className="field">
                            <label htmlFor="address">Address</label>
                            <InputTextarea id="address" value={device.address} onChange={(e) => onInputChange(e, 'address')} required rows={3} cols={20} />
                        </div>
                        <div className="field">
                            <label htmlFor="phone">Phone</label>
                            <InputText id="phone" value={device.phone} onChange={(e) => onInputChange(e, 'phone')} required  cols={20} />
                        </div>
                        <div className="field">
                            <label htmlFor="status">Status</label>
                            <Dropdown id="status" value={status} options={statusOptions} onChange={(e) => onInputChange(e, 'status')} />
                        </div>
                        <div className="field">
                            <label htmlFor="created_date">Created Date</label>
                            <Calendar id="created_date" value={createdDate} onChange={(e) => onInputChange(e, 'created_date')} showIcon={true} dateFormat="dd/mm/yy" />
                        </div>
                        <div className="field">
                            <label htmlFor="image">Image</label>
                            <FileUpload
                                name="demo"
                                chooseLabel="Upload"
                                mode="basic"
                                auto
                                accept="image/jpeg"  // Accept only JPEG images
                                onSelect={(e) => onImageSelect(e)}
                            />
                            {previewImage && (
                                <div>
                                    <label>Selected Image Preview:</label>
                                    <img src={previewImage} alt="Selected Image" width="150" />
                                </div>
                            )}
                        </div>


                    </Dialog>

                    <Dialog visible={deleteDeviceDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteDeviceDialogFooter} onHide={hideDeleteDeviceDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {device && (
                                <span>
                                    Are you sure you want to delete <b>{device.device_id}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteDevicesDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteDevicesDialogFooter} onHide={hideDeleteDevicesDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {device && <span>Are you sure you want to delete the selected devices?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default Device;
