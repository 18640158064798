import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';

const SupportPage = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [incidentType, setIncidentType] = useState(null);
    const [description, setDescription] = useState('');
    const toast = useRef(null);

    const incidentTypes = [
        { label: 'Technical Issue', value: 'Technical Issue' },
        { label: 'Billing Inquiry', value: 'Billing Inquiry' },
        { label: 'Account Management', value: 'Account Management' },
        { label: 'Other', value: 'Other' }
    ];

    const handleSubmit = (e) => {
        e.preventDefault();
        toast.current.show({severity: 'success', summary: 'Success', detail: 'Your report has been submitted.', life: 3000});

        // Handle the form submission logic here
        // For now, just resetting the form fields
        setName('');
        setEmail('');
        setIncidentType(null);
        setDescription('');
    };

    return (
        <div className="p-grid p-justify-center">
            <div className="p-col-12 p-md-8">
                <div className="card">
                    <h1>Support</h1>
                    <p>If you are experiencing any issues or have any inquiries, please fill out the form below to report the incident. Our support team will get back to you as soon as possible.</p>
                    
                    <form onSubmit={handleSubmit} className="p-fluid">
                        <div className="p-field">
                            <label htmlFor="name">Name</label>
                            <InputText id="name" value={name} onChange={(e) => setName(e.target.value)} required autoFocus />
                        </div>
                        
                        <div className="p-field">
                            <label htmlFor="email">Email</label>
                            <InputText id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                        </div>

                        <div className="p-field">
                            <label htmlFor="incidentType">Incident Type</label>
                            <Dropdown id="incidentType" value={incidentType} options={incidentTypes} onChange={(e) => setIncidentType(e.value)} placeholder="Select an Incident Type" required />
                        </div>

                        <div className="p-field">
                            <label htmlFor="description">Description</label>
                            <InputTextarea id="description" value={description} onChange={(e) => setDescription(e.target.value)} rows={5} required />
                        </div>

                        <Button label="Submit" icon="pi pi-check" type="submit" className="p-mt-2" />
                    </form>
                </div>
            </div>
            <Toast ref={toast} />
        </div>
    );
};

export default SupportPage;
