import React, { useRef, useState, useEffect, useContext } from 'react';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { Chart } from 'primereact/chart';
import { ProgressBar } from 'primereact/progressbar';
import { Menu } from 'primereact/menu';
import { Card } from 'primereact/card';
import { ProductService } from '../service/ProductService';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Timeline } from 'primereact/timeline';
import { InputText } from 'primereact/inputtext';
import { OverlayPanel } from 'primereact/overlaypanel';
import { RTLContext } from '../App';
import { ProjectService } from '../service/ProjectService';
import { DeviceService } from '../service/DeviceService';
import { GaugeService } from '../service/GaugeService';
import { GaugeDataService } from '../service/GaugeDataService'; 
import { Tree } from 'primereact/tree';
import { CustomerService } from '../service/CustomerService'; // Ensure correct path
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';



const overviewChartData1 = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September'],
    datasets: [
        {
            data: [50, 64, 32, 24, 18, 27, 20, 36, 30],
            borderColor: ['#4DD0E1'],
            backgroundColor: ['rgba(77, 208, 225, 0.8)'],
            borderWidth: 2,
            fill: true,
            tension: 0.4
        }
    ]
};

const overviewChartData2 = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September'],
    datasets: [
        {
            data: [11, 30, 52, 35, 39, 20, 14, 18, 29],
            borderColor: ['#4DD0E1'],
            backgroundColor: ['rgba(77, 208, 225, 0.8)'],
            borderWidth: 2,
            fill: true,
            tension: 0.4
        }
    ]
};

const overviewChartData3 = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September'],
    datasets: [
        {
            data: [20, 29, 39, 36, 45, 24, 28, 20, 15],
            borderColor: ['#4DD0E1'],
            backgroundColor: ['rgba(77, 208, 225, 0.8)'],
            borderWidth: 2,
            fill: true,
            tension: 0.4
        }
    ]
};

const overviewChartData4 = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September'],
    datasets: [
        {
            data: [30, 39, 50, 21, 33, 18, 10, 24, 20],
            borderColor: ['#4DD0E1'],
            backgroundColor: ['rgba(77, 208, 225, 0.8)'],
            borderWidth: 2,
            fill: true,
            tension: 0.4
        }
    ]
};

const overviewChartOptions = {
    plugins: {
        legend: {
            display: false
        }
    },
    scales: {
        y: {
            display: false
        },
        x: {
            display: false
        }
    },
    tooltips: {
        enabled: false
    },
    elements: {
        point: {
            radius: 0
        }
    }
};

const ordersChart = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September'],
    datasets: [
        {
            label: 'New Orders',
            data: [31, 83, 69, 29, 62, 25, 59, 26, 46],
            borderColor: ['#4DD0E1'],
            backgroundColor: ['rgba(77, 208, 225, 0.8)'],
            borderWidth: 2,
            fill: true,
            tension: 0.4
        },
        {
            label: 'Completed Orders',
            data: [67, 98, 27, 88, 38, 3, 22, 60, 56],
            borderColor: ['#3F51B5'],
            backgroundColor: ['rgba(63, 81, 181, 0.8)'],
            borderWidth: 2,
            fill: true,
            tension: 0.4
        }
    ]
};

const getOrdersOptions = () => {
    const textColor = getComputedStyle(document.body).getPropertyValue('--text-color') || 'rgba(0, 0, 0, 0.87)';
    const gridLinesColor = getComputedStyle(document.body).getPropertyValue('--divider-color') || 'rgba(160, 167, 181, .3)';
    const fontFamily = getComputedStyle(document.body).getPropertyValue('--font-family');
    return {
        plugins: {
            legend: {
                display: true,
                labels: {
                    fontFamily,
                    color: textColor
                }
            }
        },
        scales: {
            y: {
                ticks: {
                    fontFamily,
                    color: textColor
                },
                grid: {
                    color: gridLinesColor
                }
            },
            x: {
                ticks: {
                    fontFamily,
                    color: textColor
                },
                grid: {
                    color: gridLinesColor
                }
            }
        }
    };
};

let ordersOptions = getOrdersOptions();

// Assuming you have a function to fetch device locations
// const devices = [{ latitude: 40.730610, longitude: -73.935242 }, ...];

const containerStyle = {
    width: '100%',
    height: '400px'
  };
  
  // Example center position for the map
  const center = {
    lat: 40.730610,
    lng: -73.935242
  };


const Dashboard = (props) => {
    const [projects, setProjects] = useState([]);
    const [devices, setDevices] = useState([]);
    const [gauges, setGauges] = useState([]);
    const [gaugeData, setGaugeData] = useState([]);
   
    const [products, setProducts] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [customer, setCustomer] = useState(null);

    

    const menu1 = useRef(null);
    const menu2 = useRef(null);
    const menu3 = useRef(null);
    const menu4 = useRef(null);
    const menu5 = useRef(null);
    const menu6 = useRef(null);
    const menu7 = useRef(null);
    const menu8 = useRef(null);
    const menu9 = useRef(null);
    const menu10 = useRef(null);
    const op = useRef(null);
    const chatcontainer = useRef(null);
    const isRTL = useContext(RTLContext);

    // Example totals, replace these with your actual data fetching logic
    const totalProjects = 2;
    const totalDevices = 5;
    const totalGauges = 10;
    const totalGaugeData = 20;

    // Fixed for 6.1.0
    // eslint-disable-next-line
    const chatInput = useRef(null);
    const chart1 = useRef(null);

    const [treeData, setTreeData] = useState([]);

    useEffect(() => {
    
        const projectService = new ProjectService();
       
        projectService.getProjects().then((response) => {
            console.log("Projects:", response.data); // Log to verify
            setProjects(response.data);
        });



    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        
        const deviceService = new DeviceService();
        
        deviceService.getDevices().then((response) => {
            console.log("Devices:", response.data); // Log to verify
            setDevices(response.data);
        });



    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
     
        const gaugeService = new GaugeService();
       

        gaugeService.getGauges().then((response) => {
            console.log("Gauges:", response.data); // Log to verify
            setGauges(response.data);
        });


    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    
    useEffect(() => {
        const gaugeDataService = new GaugeDataService();
        gaugeDataService.getGaugeData().then(data => {
            console.log("Gauge Data:", data); // Log to verify
            setGaugeData(data);
        });
    }, []);
  
    useEffect(() => {
        if (projects.length && devices.length && gauges.length) {
            const treeData = projects.map(project => ({
                key: `project_${project.project_id}`, // Use project_id for project key
                label: project.name,
                data: { // Add additional project data here
                    address: project.address,
                    image_data: project.image_data
                },
                children: devices.filter(device => device.project_id === project.project_id)
                .map(device => ({
                    key: `device_${device.device_id}`, // Use device_id for device key
                    label: device.name,
                    data: { // Add additional device data here
                        model: device.model,
                        serial: device.serial
                    },
                    children: gauges.filter(gauge => gauge.device_id === device.device_id)
                    .map(gauge => ({
                        key: `gauge_${gauge.gauge_id}`, // Use gauge_id for gauge key
                        label: gauge.model, // You might also include additional data for devices and gauges if needed
                        data: { // Add additional gauge data here
                            manufacturer: gauge.manufacturer
                        },
                    }))
                }))
            }));
            
            console.log("Constructed treeData with additional details:", treeData);
            setTreeData(treeData);
        }
    }, [projects, devices, gauges]);

    const nodeTemplate = (node) => {
        // Check if this is a project node by checking if the key starts with "project_"
        if (node.key.startsWith("project_")) {
            return (
                <div>
                    {/* Displaying Project ID and Name */}
                    <b>{node.key.split('_')[1]} - {node.label}</b>
                    <p>{node.data.address}</p> {/* Project Address */}
                    {node.data.image_data && (
                        <img src={`data:image/png;base64,${node.data.image_data}`} alt={node.label} style={{ width: '50px', height: 'auto' }} />
                    )} {/* Project Image */}
                </div>
            );
        }
        // Check if this is a device node by checking if the key starts with "device_"
        else if (node.key.startsWith("device_")) {
            return (
                <div>
                    {/* Displaying Device ID, Name, and Model */}
                    <b>{node.key.split('_')[1]} - {node.label} / {node.data.model}</b>
                </div>
            );
        }
        // Check if this is a gauge node by checking if the key starts with "gauge_"
        else if (node.key.startsWith("gauge_")) {
            return (
                <div>
                    {/* Displaying gauge ID, Model and manufacturer */}
                    <b>{node.key.split('_')[1]} - {node.label} / {node.data.manufacturer}</b>
                </div>
            );
        }
        // Fallback for other types of nodes, such as gauges
        return <b>{node.label}</b>;
    };

    useEffect(() => {
        const customerService = new CustomerService();
        const customerId = localStorage.getItem('customerId'); // Assuming customerId is stored in localStorage
        if (customerId) {
            customerService.getCustomerById(customerId)
                .then(response => {
                    setCustomer(response.data);
                })
                .catch(error => {
                    console.error('Error fetching customer data:', error);
                });
        }
    }, []);

    // Render customer card
    const renderCustomerCard = () => {
        if (!customer) return null;

        return (
            <div className="card">
                <div className="flex justify-content-between align-items-center p-3">
                    <div>
                        {customer.image_data && (
                            <img src={`data:image/png;base64,${customer.image_data}`} alt="Customer Logo" style={{ width: '50px', height: 'auto' }} />
                        )}
                    </div>
                    <div className="text-right flex flex-column">
                        <span className="text-4xl">{customer.name}</span>
                        <span className="text-color-secondary mt-2">{`${customer.city} - Country`}</span> {/* Adjust 'Country' with actual country field if available */}
                    </div>
                </div>
            </div>
        );
    };

    const renderGoogleMap = () => {
        // Default center position
        const defaultCenter = {
            lat: 40.730610,
            lng: -73.935242
        };

        let mapCenter = defaultCenter;
        let zoomLevel = 10; // Default zoom level

        // If there are devices, calculate the average position to use as the map center
        if (devices.length > 0) {
            const averageLat = devices.reduce((acc, curr) => acc + curr.latitude, 0) / devices.length;
            const averageLng = devices.reduce((acc, curr) => acc + curr.longitude, 0) / devices.length;
            mapCenter = { lat: averageLat, lng: averageLng };
        }
        return (
          <LoadScript
            googleMapsApiKey="AIzaSyBSf1E2nt3CG652lkNPq1M3J_cIMSKMx7k"
          >
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={mapCenter}
              zoom={zoomLevel}
            >
              {devices.length > 0 && devices.map((device, index) => (
                    <Marker 
                        key={index} 
                        position={{ lat: device.latitude, lng: device.longitude }} 
                        title={`Device: ${device.name}`} // Optional: add a tooltip or title
                    />
                ))}
            </GoogleMap>
          </LoadScript>
        )
      };
    
    useEffect(() => {
        const productService = new ProductService();
        productService.getProducts().then((data) => setProducts(data));
        ordersOptions = getOrdersOptions();
        setOverviewColors();


    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getOverviewColors = () => {
        const isLight = props.colorMode === 'light';
        return {
            pinkBorderColor: isLight ? '#E91E63' : '#EC407A',
            pinkBgColor: isLight ? '#F48FB1' : '#F8BBD0',
            tealBorderColor: isLight ? '#009688' : '#26A69A',
            tealBgColor: isLight ? '#80CBC4' : '#B2DFDB'
        };
    };

    const setOverviewColors = () => {
        const { pinkBorderColor, pinkBgColor, tealBorderColor, tealBgColor } = getOverviewColors();

        overviewChartData1.datasets[0].borderColor[0] = tealBorderColor;
        overviewChartData1.datasets[0].backgroundColor[0] = tealBgColor;

        overviewChartData2.datasets[0].borderColor[0] = tealBorderColor;
        overviewChartData2.datasets[0].backgroundColor[0] = tealBgColor;

        overviewChartData3.datasets[0].borderColor[0] = pinkBorderColor;
        overviewChartData3.datasets[0].backgroundColor[0] = pinkBgColor;

        overviewChartData4.datasets[0].borderColor[0] = tealBorderColor;
        overviewChartData4.datasets[0].backgroundColor[0] = tealBgColor;
    };

    useEffect(() => {
        if (props.isNewThemeLoaded) {
            ordersOptions = getOrdersOptions();
            props.onNewThemeChange(false);
            setOverviewColors();
        }
    }, [props.isNewThemeLoaded, props.onNewThemeChange]); // eslint-disable-line react-hooks/exhaustive-deps

    const timelineEvents = [
        { status: 'Ordered', date: '15/10/2020 10:30', icon: 'pi pi-shopping-cart', color: '#E91E63', description: 'Richard Jones (C8012) has ordered a blue t-shirt for $79.' },
        { status: 'Processing', date: '15/10/2020 14:00', icon: 'pi pi-cog', color: '#FB8C00', description: 'Order #99207 has processed succesfully.' },
        { status: 'Shipped', date: '15/10/2020 16:15', icon: 'pi pi-compass', color: '#673AB7', description: 'Order #99207 has shipped with shipping code 2222302090.' },
        { status: 'Delivered', date: '16/10/2020 10:00', icon: 'pi pi-check-square', color: '#0097A7', description: 'Richard Jones (C8012) has recieved his blue t-shirt.' }
    ];

    const [chatMessages, setChatMessages] = useState([
        { from: 'Ioni Bowcher', url: 'assets/demo/images/avatar/ionibowcher.png', messages: ['Hey M. hope you are well.', 'Our idea is accepted by the board. Now it’s time to execute it'] },
        { messages: ['We did it! 🤠'] },
        { from: 'Ioni Bowcher', url: 'assets/demo/images/avatar/ionibowcher.png', messages: ["That's really good!"] },
        { messages: ['But it’s important to ship MVP ASAP'] },
        { from: 'Ioni Bowcher', url: 'assets/demo/images/avatar/ionibowcher.png', messages: ['I’ll be looking at the process then, just to be sure 🤓'] },
        { messages: ['That’s awesome. Thanks!'] }
    ]);

    const chatEmojis = [
        '😀',
        '😃',
        '😄',
        '😁',
        '😆',
        '😅',
        '😂',
        '🤣',
        '😇',
        '😉',
        '😊',
        '🙂',
        '🙃',
        '😋',
        '😌',
        '😍',
        '🥰',
        '😘',
        '😗',
        '😙',
        '😚',
        '🤪',
        '😜',
        '😝',
        '😛',
        '🤑',
        '😎',
        '🤓',
        '🧐',
        '🤠',
        '🥳',
        '🤗',
        '🤡',
        '😏',
        '😶',
        '😐',
        '😑',
        '😒',
        '🙄',
        '🤨',
        '🤔',
        '🤫',
        '🤭',
        '🤥',
        '😳',
        '😞',
        '😟',
        '😠',
        '😡',
        '🤬',
        '😔',
        '😟',
        '😠',
        '😡',
        '🤬',
        '😔',
        '😕',
        '🙁',
        '😬',
        '🥺',
        '😣',
        '😖',
        '😫',
        '😩',
        '🥱',
        '😤',
        '😮',
        '😱',
        '😨',
        '😰',
        '😯',
        '😦',
        '😧',
        '😢',
        '😥',
        '😪',
        '🤤'
    ];

    // Fixed for 6.1.0
    // eslint-disable-next-line
    const onEmojiClick = (chatInput, emoji) => {
        if (chatInput) {
            chatInput.current.element.value += emoji;
            chatInput.current.element.focus();
        }
    };

    const onChatKeydown = (event) => {
        if (event.key === 'Enter') {
            let message = event.target.value;
            let newChatMessages = [...chatMessages];
            let lastMessage = newChatMessages[newChatMessages.length - 1];

            if (lastMessage.from) {
                newChatMessages.push({ messages: [message] });
                setChatMessages(newChatMessages);
            } else {
                lastMessage.messages.push(message);
                setChatMessages(newChatMessages);
            }

            if (message.match(/primeng|primereact|primefaces|primevue/i)) {
                newChatMessages.push({ from: 'Ioni Bowcher', url: 'assets/demo/images/avatar/ionibowcher.png', messages: ['Always bet on Prime!'] });
                setChatMessages(newChatMessages);
            }

            event.target.value = '';

            const el = chatcontainer.current;
            setTimeout(() => {
                el.scroll({
                    top: el.scrollHeight,
                    behavior: 'smooth'
                });
            }, 1);
        }
    };

    const marker = (item) => {
        return (
            <span className="custom-marker shadow-2 p-2" style={{ backgroundColor: item.color }}>
                <i className={classNames('marker-icon', item.icon)}></i>
            </span>
        );
    };

    const content = (item) => {
        return (
            <Card className="mb-3" title={item.status} subTitle={item.date}>
                {item.image && <img src={`showcase/demo/images/product/${item.image}`} alt={item.name} width={200} className="shadow-2" />}
                <p>{item.description}</p>
            </Card>
        );
    };

    const imageTemplate = (rowData, column) => {
        var src = 'assets/demo/images/product/' + rowData.image;
        return <img src={src} alt={rowData.brand} width="50px" className="shadow-4" />;
    };

    const actionTemplate = (rowData, column) => {
        return (
            <>
                <span className="p-column-title">View</span>
                <Button icon="pi pi-search" type="button" className={classNames('p-button-rounded p-button-text mb-1', { 'mr-2': !isRTL, 'ml-2': isRTL })}></Button>
            </>
        );
    };

    const priceBodyTemplate = (data) => {
        return (
            <>
                <span className="p-column-title">Price</span>
                {formatCurrency(data.price)}
            </>
        );
    };

    const bodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        );
    };

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    };

    return (
        <div className="grid dashboard">
            <div className="col-12 md:col-6 lg:col-4">
                {renderCustomerCard()}
            </div>
            <div className="col-12 md:col-6 lg:col-2">
                <div className="card">
                    <div className="flex justify-content-between align-items-center p-3">
                        <div>
                            <i className="pi pi-id-card text-blue-500 text-6xl"></i>
                        </div>
                        <div className="text-right flex flex-column">
                            <span className="text-4xl">{projects.length}</span>
                            <span className="text-color-secondary mt-2">Projects</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12 md:col-6 lg:col-2">
                <div className="card">
                    <div className="flex justify-content-between align-items-center p-3">
                        <div>
                            <i className="pi pi-briefcase text-blue-500 text-6xl"></i>
                        </div>
                        <div className="text-right flex flex-column">
                            <span className="text-4xl">{devices.length}</span>
                            <span className="text-color-secondary mt-2">Devices</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12 md:col-6 lg:col-2">
                <div className="card">
                    <div className="flex justify-content-between align-items-center p-3">
                        <div>
                            <i className="pi pi-database text-blue-500 text-6xl"></i>
                        </div>
                        <div className="text-right flex flex-column">
                            <span className="text-4xl">{gauges.length}</span>
                            <span className="text-color-secondary mt-2">Gauges</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12 md:col-6 lg:col-2">
                <div className="card">
                    <div className="flex justify-content-between align-items-center p-3">
                        <div>
                            <i className="pi pi-chart-bar text-blue-500 text-6xl"></i>
                        </div>
                        <div className="text-right flex flex-column">
                            <span className="text-4xl">{gaugeData.length}</span>
                            <span className="text-color-secondary mt-2">Gauge Data</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12 lg:col-12">
                <div className="grid">
                    <div className="col-12 lg:col-4">
                        <Tree value={treeData} nodeTemplate={nodeTemplate} />
                    </div>
                    <div className="col-12 lg:col-8">
                        {renderGoogleMap()}
                    </div>
                </div>
            </div>


           
           

        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode && prevProps.isNewThemeLoaded === nextProps.isNewThemeLoaded && prevProps.onNewThemeChange === nextProps.onNewThemeChange;
};

export default React.memo(Dashboard, comparisonFn);
