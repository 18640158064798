import axios from 'axios';


// // Define the base URLs for the local and remote servers
const LOCAL_API_BASE_URL = 'http://localhost:5001';
const REMOTE_API_BASE_URL = 'https://api.mysensorhub.com';

// Define the server variable ('local' or 'remote')
const server = 'remote';  // Change this to 'remote' when needed

// Define the base URL based on the server variable
const API_BASE_URL = server === 'local' ? LOCAL_API_BASE_URL : REMOTE_API_BASE_URL;

const apiClient = axios.create({
  baseURL: API_BASE_URL
});

// Response interceptor
apiClient.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // Handle token expiration (e.g., redirect to login, refresh token, etc.)
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export class DeviceService {

  // Helper function to get headers with Authorization token
  getAuthHeaders() {
    const accessToken = localStorage.getItem('accessToken');
    return {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    };
  }

  getDevices() {
    const customerId = localStorage.getItem('customerId');  // Retrieve customer_id from local storage
    const url = `/devices/${customerId}`;
    return apiClient.get(url, this.getAuthHeaders());
  }

  addDevice(device) {
    return apiClient.post('/devices', device, this.getAuthHeaders());
  }

  updateDevice(device, device_id) {
    return apiClient.put(`/devices/${device_id}`, device, this.getAuthHeaders());
  }

  deleteDevice(device_id) {
    return apiClient.delete(`/device/${device_id}`, this.getAuthHeaders());
  }

  getDeviceById(device_id) {
    return apiClient.get(`/device/${device_id}`, this.getAuthHeaders());
  }

  getProjects() {
    const customerId = localStorage.getItem('customerId');  // Retrieve customer_id from local storage
    const url = `/projects2/${customerId}`;
    return apiClient.get(url, this.getAuthHeaders());
  }

  getDevicesForProject(project_id) {
    const url = `/devices/project/${project_id}`;
    return apiClient.get(url, this.getAuthHeaders());
  }
//   getDevicesByDevice() {
//     return axios.get(API_BASE_URL + '/devices/device'+ device_id);
//   }

}
