import axios from 'axios';

// // Define the base URLs for the local and remote servers
const LOCAL_API_BASE_URL = 'http://localhost:5001';
const REMOTE_API_BASE_URL = 'https://api.mysensorhub.com';

// Define the server variable ('local' or 'remote')
const server = 'remote';  // Change this to 'remote' when needed

// Define the base URL based on the server variable
const API_BASE_URL = server === 'local' ? LOCAL_API_BASE_URL : REMOTE_API_BASE_URL;

const apiClient = axios.create({
  baseURL: API_BASE_URL
});

// Response interceptor
apiClient.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // Handle token expiration (e.g., redirect to login, refresh token, etc.)
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export class ProjectService {
  // Helper function to get headers with Authorization token
  getAuthHeaders() {
    const accessToken = localStorage.getItem('accessToken');
    return {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    };
  }

  getProjects() {
    const customerId = localStorage.getItem('customerId');  // Retrieve customer_id from local storage
    const url = `/projects/${customerId}`;
    return apiClient.get(url, this.getAuthHeaders());
  }

  addProject(project) {
    return apiClient.post('/projects', project, this.getAuthHeaders());
  }

  updateProject(project, project_id) {
    return apiClient.put(`/projects/${project_id}`, project, this.getAuthHeaders());
  }

  deleteProject(project_id) {
    return apiClient.delete(`/project/${project_id}`, this.getAuthHeaders());
  }

  getProjectById(project_id) {
    console.log('Fetching project by ID:', project_id); // Log API call
    return apiClient.get(`/project/${project_id}`, this.getAuthHeaders());
  }
}
