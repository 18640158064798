import React, { useState, useEffect, useRef } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputSwitch } from 'primereact/inputswitch';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { SubscriptionTypeService } from '../service/SubscriptionTypeService';

const SubscriptionType = () => {
    let  emptySubscriptionType = {
        type_id: null,
        type_name: '',
        description: '',
        price: 0.0,
        discount:0,
        days:0,
        fg_yearly:0,
        max_gauges:0,
        created_at: new Date(),
        // Add other fields as needed
    };

    const [subscriptionTypes, setSubscriptionTypes] = useState([]);
    const [subscriptionTypeDialog, setSubscriptionTypeDialog] = useState(false);
    const [deleteSubscriptionTypeDialog, setDeleteSubscriptionTypeDialog] = useState(false);
    const [deleteSubscriptionTypesDialog, setDeleteSubscriptionTypesDialog] = useState(false);
    const [subscriptionType, setSubscriptionType] = useState(emptySubscriptionType);
    const [selectedSubscriptionTypes, setSelectedSubscriptionTypes] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [createdAt, setCreatedAt] = useState(new Date());
    // const subscriptionTypeService = useRef(new SubscriptionTypeService());

    useEffect(() => {
        const subscriptionTypeService = new SubscriptionTypeService();
        subscriptionTypeService.getSubscriptionTypes().then((response) => {
            setSubscriptionTypes(response.data);
        });
    }, []);

    const openNew = () => {
        setSubscriptionType(emptySubscriptionType);
        setSubmitted(false);
        setSubscriptionTypeDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setSubscriptionTypeDialog(false);
    };

    const hideDeleteSubscriptionTypeDialog = () => {
        setDeleteSubscriptionTypeDialog(false);
    };

    const hideDeleteSubscriptionTypesDialog = () => {
        setDeleteSubscriptionTypesDialog(false);
    };
    
    
    const saveSubscriptionType = () => {
        setSubmitted(true);
    
        // if (subscriptionType.name.trim() && subscriptionType.image) { // Check if an image is selected+
        if (validateRecord(subscriptionType)) {
            
            const subscriptionTypeService = new SubscriptionTypeService();
            // const isNewSubscriptionType = !subscriptionType.type_id;
            if (subscriptionType.type_id==null) {
                
                subscriptionTypeService
                    .addSubscriptionType(subscriptionType)
                    .then((response) => {
                        // Handle the response for a new subscriber
                        // setSubscriptionTypes([...subscriptionTypes, response.data]);
                        setSubscriptionTypes([...subscriptionTypes, subscriptionType]);
                        toast.current.show({
                            severity: 'success',
                            summary: 'Successful',
                            detail: 'Subscription Type Created',
                            life: 3000,
                        });
                        setSubscriptionTypeDialog(false);
                        setSubscriptionType(emptySubscriptionType);
                    })
                    .catch((error) => {
                        console.error('Error saving new SubscriptionType:', error);
                    });
            } else {
                
                subscriptionTypeService
                    .updateSubscriptionType(subscriptionType, subscriptionType.type_id)
                    .then((response) => {
                        // Handle the response for an updated subscriber
                        const updatedSubscriptionTypes = subscriptionTypes.map((s) =>
                            s.type_id === response.data.type_id ? subscriptionType : s
                        );
                        setSubscriptionTypes(updatedSubscriptionTypes);
                        toast.current.show({
                            severity: 'success',
                            summary: 'Successful',
                            detail: 'SubscriptionType Updated',
                            life: 3000,
                        });
                        setSubscriptionTypeDialog(false);
                        setSubscriptionType(emptySubscriptionType);
                    })
                    .catch((error) => {
                        console.error('Error updating SubscriptionType:', error);
                    });
            }
        }
    };

    const validateRecord = (subscription) => {
        // Check if the subscription object is not null
        return subscription !== null && subscription !== undefined;
    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < subscriptionTypes.length; i++) {
            if (subscriptionTypes[i].type_id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const editSubscriptionType = (subscriptionType) => {
        setSubscriptionType({ ...subscriptionType });
        setSubscriptionTypeDialog(true);
    };

    const confirmDeleteSubscriptionType = (subscriptionType) => {
        setSubscriptionType(subscriptionType);
        setDeleteSubscriptionTypeDialog(true);
    };

    const deleteSubscriptionType = () => {
        const subscriptionTypeService = new SubscriptionTypeService();
        subscriptionTypeService
            .deleteSubscriptionType(subscriptionType.type_id)
            .then(() => {
                // Delete the subscription from the local state
                let updatedSubscriptions = subscriptionTypes.filter((val) => val.type_id !== subscriptionType.type_id);
                setSubscriptionTypes(updatedSubscriptions);
                setDeleteSubscriptionTypeDialog(false);
                setSubscriptionType(emptySubscriptionType);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Subscription Type Deleted', life: 3000 });
            })
            .catch((error) => {
                console.error('Error deleting Subscription Type:', error);
                // Handle any error in the API call
            });

    };

    
    
    
    const formatDate = (value) => {
        if (value) {
            const date = new Date(value); // Convert string to Date object
            return date.toLocaleDateString('en-US', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
        }
        return ''; // Return an empty string if value is not defined
    }

    
    const exportCSV = () => {
        dt.current.exportCSV();
    };


    const confirmDeleteSelected = () => {
        setDeleteSubscriptionTypesDialog(true);
    };

    const deleteSelectedSubscriptionTypes = () => {
        let _subscriptionTypes = subscriptionTypes.filter((val) => !selectedSubscriptionTypes.includes(val));
        setSubscriptionTypes(_subscriptionTypes);
        setDeleteSubscriptionTypesDialog(false);
        setSelectedSubscriptionTypes(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Subscriptions Type Deleted', life: 3000 });
    };

    // const onInputChange = (e, name) => {
    //     const val = (e.target && e.target.value) || '';
    //     let _subscriptionType = { ...subscriptionType };

    //     // Special handling for switch to convert boolean to 0/1
    //     if(name === 'fg_yearly') {
    //         val = e.value ? 1 : 0;
    //     }

    //     _subscriptionType[`${name}`] = val;

    //     setSubscriptionType(_subscriptionType);
    // };

    const onInputChange = (e, name) => {
        let val;
        if(name === 'fg_yearly') {
            val = e.value ? 1 : 0; // Handle boolean for fg_yearly
        } else {
            val = e.target ? e.target.value : '';
        }
    
        setSubscriptionType(prevState => ({
            ...prevState,
            [name]: val
        }));
    };
    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _subscriptionType = { ...subscriptionType };
        _subscriptionType[`${name}`] = val;

        setSubscriptionType(_subscriptionType);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedSubscriptionTypes || !selectedSubscriptionTypes.length} />
                </div>
            </React.Fragment>
        );

    };
    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" />
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    const idBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Id</span>
                {rowData.type_id}
            </>
        );
    };

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.type_name}
            </>
        );
    };

    const descriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Description</span>
                {rowData.description}
            </>
        );
    };

    const daysBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Days</span>
                {rowData.days}
            </>
        );
    };

    const discountBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Discount</span>
                {rowData.discount}
            </>
        );
    };

    const priceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Price</span>
                {rowData.price}
            </>
        );
    };
    const maxGaugesBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Max Gauges</span>
                {rowData.max_gauges}
            </>
        );
    };
    const fgBodyTemplate = (rowData) => {

        const iconClassName = classNames("pi", {
            "pi-circle-on": rowData.status === 0,
            "pi-check": rowData.status === 1
        });
    
        return (
        <>
            <span className="p-column-title {iconClassName}">Yearly</span>
            {rowData.status === 0 ? "Disable" : "Enable"}
        </>
        );    
    };


    // const actionBodyTemplate = (rowData) => {
    //     return (
    //         <React.Fragment>
    //             <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editSubscriptionType(rowData)} />
    //             <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteSubscriptionType(rowData)} />
    //         </React.Fragment>
    //     );
    // };

    const actionBodyTemplate = (rowData) => {
        const buttonGroupStyle = {
            display: 'flex',      // Make the buttons display in a row
            justifyContent: 'space-between', // Distribute space between buttons
        };
    
        const buttonStyle = {
            flex: '1',            // Allow buttons to grow and share available space
            marginRight: '2px',   // Adjust spacing between buttons
        };
        return (
            <div className="actions" style={buttonGroupStyle}>
                <Button 
                    icon="pi pi-pencil" 
                    className="p-button-rounded p-button-success" 
                    style={buttonStyle}
                    onClick={() => editSubscriptionType(rowData)} />
                <Button 
                    icon="pi pi-trash" 
                    className="p-button-rounded p-button-warning" 
                    style={buttonStyle}
                    onClick={() => confirmDeleteSubscriptionType(rowData)} />
            </div>
        );
    };

    
    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Subscriptions Types</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const subscriptionTypeDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveSubscriptionType} />
        </>
    );

    const deleteSubscriptionTypeDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteSubscriptionTypeDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSubscriptionType} />
        </>
    );

    const deleteSubscriptionTypesDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteSubscriptionTypesDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedSubscriptionTypes} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    <DataTable 
                        ref={dt}
                        value={subscriptionTypes} 
                        selection={selectedSubscriptionTypes} 
                        onSelectionChange={(e) => setSelectedSubscriptionTypes(e.value)}
                        dataKey="type_id" 
                        paginator 
                        rows={10} 
                        rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} subscriptions types"
                        globalFilter={globalFilter}
                        emptyMessage="No Subscription Type found."
                        header={header}
                        >
                            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                            
                            <Column field="type_id" header="ID" body={idBodyTemplate}></Column>
                            <Column field="type_name" header="Name" body={nameBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                            <Column field="description" header="Description" body={descriptionBodyTemplate} headerStyle={{ width: '30%', minWidth: '10rem' }}></Column>
                            <Column field="days" header="Days" sortable body={daysBodyTemplate} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                            <Column field="price" header="Price" sortable body={priceBodyTemplate} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                            <Column field="discount" header="Discount" sortable body={discountBodyTemplate} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                            <Column field="fg_yearly" header="Yearly" body={fgBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '8rem' }}></Column>
                            <Column field="max_gauges" header="Max Gauges" sortable body={maxGaugesBodyTemplate} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                            <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>
            
            <Dialog visible={subscriptionTypeDialog} style={{ width: '450px' }} header="Subscription Type Details" modal className="p-fluid" footer={subscriptionTypeDialogFooter} onHide={hideDialog}>
                <div className="field">
                    <label htmlFor="name">Name</label>
                    <InputText id="name" value={subscriptionType.type_name} onChange={(e) => onInputChange(e, 'type_name')} required  cols={20} />
                </div>

                <div className="field">
                    <label htmlFor="description">Features</label>
                    <InputTextarea id="description" value={subscriptionType.description} onChange={(e) => onInputChange(e, 'description')} rows={5} cols={20} autoResize />
                    {submitted && !subscriptionType.description && <small className="p-error">Description is required.</small>}
                </div>

                <div className="field">
                    <label htmlFor="days">Days</label>
                    <InputNumber  id="days" value={subscriptionType.days} onChange={(e) => onInputNumberChange(e, 'days')} required  cols={20} />
                </div>
                <div className="field">
                    <label htmlFor="price">Price</label>
                    <InputNumber  id="price" value={subscriptionType.price} onChange={(e) => onInputNumberChange(e, 'price')} required  cols={20} />
                </div>
                <div className="field">
                    <label htmlFor="discount">Discount</label>
                    <InputNumber id="discount" value={subscriptionType.discount} onValueChange={(e) => onInputChange(e, 'discount')} mode="decimal" min={0} max={100} required />
                </div>
                <div className="field">
                    <label htmlFor="fg_yearly">Yearly Subscription</label>
                    <InputSwitch id="fg_yearly" checked={subscriptionType.fg_yearly === 1} onChange={(e) => onInputChange(e, 'fg_yearly')} />
                </div>
                <div className="field">
                    <label htmlFor="max_gauges">Max gauges</label>
                    <InputNumber  id="maxgauges" value={subscriptionType.max_gauges} onChange={(e) => onInputNumberChange(e, 'max_gauges')} required  cols={20} />
                </div>

            </Dialog>

            <Dialog visible={deleteSubscriptionTypeDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteSubscriptionTypeDialogFooter} onHide={hideDeleteSubscriptionTypeDialog}>
                <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {subscriptionType && <span>Are you sure you want to delete the subscription type <b>{subscriptionType.type_name}</b>?</span>}
                </div>
            </Dialog>

            <Dialog visible={deleteSubscriptionTypesDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteSubscriptionTypesDialogFooter} onHide={hideDeleteSubscriptionTypesDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {subscriptionType && <span>Are you sure you want to delete the selected subscription Types?</span>}
                </div>
            </Dialog>
        </div>
    );
};

export default SubscriptionType;
