import React, { useContext,useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { MegaMenu } from 'primereact/megamenu';
import { useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { CSSTransition } from 'react-transition-group';
import { RTLContext } from './App';
import { CustomerService } from './service/CustomerService';


const AppTopbar = (props) => {
    const isRTL = useContext(RTLContext);
    const navigate = useNavigate();

    let emptyUser = {
        id: null,
        customer_id: 0,
        first_name: '',
        last_name: '',
        email: '',
        password: '', // Consider handling password securely
        phone: '',
        rol: 0,
        created: new Date() // Or null, depending on how you handle dates
    };
    let emptyCustomer = {
        customer_id: null,
        name: '',
        address1: '',
        address2: '',
        city: '',
        state_id: null, // You might need to handle state and country IDs carefully
        country_id: null,
        zipcode: '',
        image_data: null, // If you're handling image uploads
        created_at: new Date() // Or null, depending on your date handling
    };
    const topbarRef1 = useRef(null);
    const topbarRef2 = useRef(null);
    const topbarRef3 = useRef(null);
    const topbarRef4 = useRef(null);

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(emptyUser);
    const [customer, setCustomer] = useState(emptyCustomer);    
    const customerId = localStorage.getItem('customerId'); // Retrieve customer_id from local storage
    const customerService = new CustomerService();
    // Fixed for 6.1.0
    // eslint-disable-next-line
    const searchPanel = useRef(null);

    useEffect(() => {
        // Fixed for 6.1.0
        /*if (props.searchActive) {
            searchPanel.current.element.focus();
        }*/
    }, [props.searchActive]);

    useEffect(() => {
        const userId = localStorage.getItem('userId');
        const customerId = localStorage.getItem('customerId');

        if (userId && customerId) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
    }, []);

    useEffect(() => {
        if (isAuthenticated && customerId) {
            customerService.getCustomerById(customerId)
                .then(response => {
                    setCustomer(response.data);
                })
                .catch(error => {
                    console.error('Error fetching customer data:', error);
                });
        }
    }, [isAuthenticated, customerId]);

    const onInputKeydown = (event) => {
        const key = event.which;

        //escape, tab and enter
        if (key === 27 || key === 9 || key === 13) {
            props.onSearch(false);
        }
    };

    const model = [];

    return (
        <div className="layout-topbar shadow-4">
            <div className="layout-topbar-left">
                <button type="button" style={{ cursor: 'pointer' }} className="layout-topbar-logo p-link" onClick={() => navigate('/')}>
                    <img id="app-logo" src="assets/layout/images/logo-smartsensor.svg" alt="ultima-layout" style={{ height: '2.25rem' }} />
                </button>
                <button type="button" className="layout-menu-button shadow-6 p-link" onClick={props.onMenuButtonClick}>
                    <i className="pi pi-chevron-right"></i>
                </button>
                <button type="button" className="layout-topbar-mobile-button p-link">
                    <i className="pi pi-ellipsis-v fs-large" onClick={props.onMobileTopbarButtonClick}></i>
                </button>
            </div>

            <div className={classNames('layout-topbar-right', { 'layout-topbar-mobile-active': props.mobileTopbarActive })}>
                <div className="layout-topbar-actions-left">
                    <MegaMenu model={model} className="layout-megamenu" />
                </div>
                <div className="layout-topbar-actions-right">
                    <ul className="layout-topbar-items">
                        
                        <li className="layout-topbar-item">
                        <button className="layout-topbar-action flex flex-row justify-content-center align-items-center px-2 rounded-circle p-link" onClick={(event) => props.onTopbarItemClick({ originalEvent: event, item: 'profile' })}>
                            <img src={`data:image/png;base64,${customer.image_data}`} alt="avatar" style={{ width: '32px', height: '32px' }} />
                        </button>

                            <CSSTransition nodeRef={topbarRef4} classNames="p-toggleable" timeout={{ enter: 1000, exit: 450 }} in={props.activeTopbarItem === 'profile'} unmountOnExit>
                                <ul ref={topbarRef4} className="layout-topbar-action-panel shadow-6">
                                    <li className="layout-topbar-action-item">
                                        <button className="flex flex-row align-items-center p-link" onClick={() => navigate('/pages/account')}>
                                            <i className={classNames('pi pi-cog', { 'mr-2': !isRTL, 'ml-2': isRTL })}></i>
                                            <span>Profile</span>
                                        </button>
                                    </li>
                                    <li className="layout-topbar-action-item">
                                        <button className="flex flex-row align-items-center p-link" onClick={() => navigate('/pages/termsOfUse')}>
                                            <i className={classNames('pi pi-file', { 'mr-2': !isRTL, 'ml-2': isRTL })}></i>
                                            <span>Terms of Usage</span>
                                        </button>
                                    </li>
                                    <li className="layout-topbar-action-item ">
                                        <button className="flex flex-row align-items-center p-link" onClick={() => navigate('/pages/supportPage')}>
                                            <i className={classNames('pi pi-compass', { 'mr-2': !isRTL, 'ml-2': isRTL })}></i>
                                            <span>Support</span>
                                        </button>
                                    </li>
                                    <li className="layout-topbar-action-item">
                                        <button className="flex flex-row align-items-center p-link" onClick={() => navigate('/Logout')}>
                                            <i className={classNames('pi pi-power-off', { 'mr-2': !isRTL, 'ml-2': isRTL })}></i>
                                            <span>Logout</span>
                                        </button>
                                    </li>
                                </ul>
                            </CSSTransition>
                        </li>
                        <li className="layout-topbar-item">
                            <button type="button" className="layout-topbar-action rounded-circle p-link" onClick={props.onRightMenuButtonClick}>
                                <i className="pi fs-large pi-arrow-left"></i>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default AppTopbar;
