import axios from 'axios';

// // Define the base URLs for the local and remote servers
const LOCAL_API_BASE_URL = 'http://localhost:5001';
const REMOTE_API_BASE_URL = 'https://api.mysensorhub.com';

// Define the server variable ('local' or 'remote')
const server = 'remote';  // Change this to 'remote' when needed

// Define the base URL based on the server variable
const API_BASE_URL = server === 'local' ? LOCAL_API_BASE_URL : REMOTE_API_BASE_URL;

const apiClient = axios.create({
  baseURL: API_BASE_URL
});

// Response interceptor
apiClient.interceptors.response.use(
  response => response,
  error => {
      if (error.response && error.response.status === 401) {
          // Handle token expiration (e.g., redirect to login, refresh token, etc.)
          window.location.href = '/login';
      }
      return Promise.reject(error);
  }
);

export class SubscriptionService {

  // Helper function to get headers with Authorization token
  getAuthHeaders() {
    const accessToken = localStorage.getItem('accessToken');
    return {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    };
  }

  getSubscriptions() {
    const url = `/subscriptions`;
    return apiClient.get(url, this.getAuthHeaders());
  }

  getSubscriptionsByCustomerId(customerId) {
    return apiClient.get(`/subscriptions/customer/${customerId}`, this.getAuthHeaders());
  }

  addSubscription(subscription) {
    return apiClient.post('/subscriptions', subscription, this.getAuthHeaders());
  }
  
  updateSubscription(subscription, subscription_id) {
    return apiClient.put(`/subscriptions/${subscription_id}`, subscription, this.getAuthHeaders());
  }

  deleteSubscription(subscription_id) {
    return apiClient.delete(`/subscriptions/${subscription_id}`, this.getAuthHeaders());
  }

  // Add other methods as needed
}
