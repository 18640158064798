import React, { useState, useEffect, useRef } from 'react';
// import { Icon } from "primereact/icon";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { GaugeService } from '../service/GaugeService';
import { v4 as uuidv4 } from 'uuid';

const Gauge = () => {
    let emptyGauge = {
        gauge_id: null,
        device_id:0,
        model: '',
        serial: '',
        manufacturer: '',
        port: 0,
        init_value:0.0,
        actual_value:0.0,
        image_data: null, // Add the image property
        status: 0,
        created_Date: new Date(),
    };

    const [gauges, setGauges] = useState([]);
    const [gaugeDialog, setGaugeDialog] = useState(false);
    const [deleteGaugeDialog, setDeleteGaugeDialog] = useState(false);
    const [deleteGaugesDialog, setDeleteGaugesDialog] = useState(false);
    const [gauge, setGauge] = useState(emptyGauge);
    const [selectedGauges, setSelectedGauges] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [status, setStatus] = useState(0);
    const [createdDate, setCreatedDate] = useState(new Date());
    const [closedDate, setClosedDate] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);

    const [selectedDeviceId, setSelectedDeviceId] = useState(0); // Initialize with a default value or null
    const [devices, setDevices] = useState([]); // Initialize with an empty array


    const statusOptions = [
        {label: 'Active', value: 0},
        {label: 'Inactive', value: 1},
    ];

    useEffect(() => {
        const gaugeService = new GaugeService();
        // gaugeService.getGauges().then((response) => console.log(response.data));
        // gaugeService.getGauges().then((data) => setGauges(data));
        gaugeService.getGauges().then((response) => {
            setGauges(response.data);
        });

        // Fetch the list of devices and store them in the 'devices' state variable
        gaugeService.getDevices().then((response) => {
            setDevices(response.data);
        });
      
    }, []);

    
    const openNew = () => {
        setGauge(emptyGauge);
        setSelectedDeviceId(gauge.device_id); 
        setCreatedDate(new Date(gauge.created_Date));
        setSubmitted(false);
        setGaugeDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setGaugeDialog(false);
    };

    const hideDeleteGaugeDialog = () => {
        setDeleteGaugeDialog(false);
    };

    const hideDeleteGaugesDialog = () => {
        setDeleteGaugesDialog(false);
    };


    const saveGauge = () => {
        setSubmitted(true);
    
        gauge.device_id = selectedDeviceId;
        gauge.created_Date=createdDate;

        // if (gauge.name.trim() && gauge.image) { // Check if an image is selected+
        if (validateRecord(gauge)) {
            // let _gauges = [...gauges];
            // let _gauge = { ...gauge };
            const gaugeService = new GaugeService();
            // const isNewGauge = !gauge.gauge_id;           
            if (gauge.gauge_id==null) {
                // gauge.gauge_id = createId();
                // project.customer_id=localStorage.getItem('customerId');
                gaugeService
                    .addGauge(gauge)
                    .then((response) => {
                        // Handle the response for a new subscriber
                        // setGauges([...gauges, response.data]);
                        setGauges([...gauges, gauge]);
                        toast.current.show({
                            severity: 'success',
                            summary: 'Successful',
                            detail: 'Gauge Created',
                            life: 3000,
                        });
                        setGaugeDialog(false);
                        setGauge(emptyGauge);
                    })
                    .catch((error) => {
                        console.error('Error saving new Gauge:', error);
                    });
            } else {

                gaugeService
                    .updateGauge(gauge, gauge.gauge_id)
                    .then((response) => {
                        // Handle the response for an updated subscriber
                        const updatedGauges = gauges.map((s) =>
                            s.gauge_id === response.data.gauge_id ? gauge : s
                        );
                        setGauges(updatedGauges);
                        toast.current.show({
                            severity: 'success',
                            summary: 'Successful',
                            detail: 'Gauge Updated',
                            life: 3000,
                        });
                        setGaugeDialog(false);
                        setGauge(emptyGauge);
                    })
                    .catch((error) => {
                        console.error('Error updating Gauge:', error);
                    });
            }
        }
    };

    const validateRecord = (record) => {
        // Check if the subscription object is not null
        return record !== null && record !== undefined;
    };
    
    const editGauge= (gauge) => {
        setGauge({ ...gauge });
        setSelectedDeviceId(gauge.device_id); 
        setCreatedDate(new Date(gauge.created_Date));
        setGaugeDialog(true);
    };

    const confirmDeleteGauge = (gauge) => {
        setGauge(gauge);
        setDeleteGaugeDialog(true);
    };

 
    const deleteGauge = () => {
        const gaugeService = new GaugeService();
        gaugeService
            .deleteGauge(gauge.gauge_id)
            .then(() => {
                // Delete the gauge from the local state
                let updatedGauges = gauges.filter((val) => val.gauge_id !== gauge.gauge_id);
                setGauges(updatedGauges);
                setDeleteGaugeDialog(false);
                setGauge(emptyGauge);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Gauge Deleted', life: 3000 });
            })
            .catch((error) => {
                console.error('Error deleting Gauge:', error);
                // Handle any error in the API call
            });

    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < gauges.length; i++) {
            if (gauges[i].gauge_id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                {rowData.image_data ? (
                    <img src={`data:image/png;base64,${rowData.image_data}`} alt="Gauge Image" className="shadow-2" width="100" />
                ) : (
                    'No Image'
                )}
            </>
        );
    };
      
    
    const formatDate = (value) => {
        if (value) {
            return value.toLocaleDateString('en-US', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
        }
        return ''; // Return an empty string if value is not defined
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const confirmDeleteSelected = () => {
        setDeleteGaugesDialog(true);
    };

    const deleteSelectedGauges = () => {
        let _gauges = gauges.filter((val) => !selectedGauges.includes(val));
        setGauges(_gauges);
        setDeleteGaugesDialog(false);
        setSelectedGauges(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Gauges Deleted', life: 3000 });
    };


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _gauge = { ...gauge };
        _gauge[`${name}`] = val;

        if (name === 'status') {
            setStatus(val);
        } else if (name === 'created_date') {
            setCreatedDate(val);
        } else if (name === 'closed_date') {
            setClosedDate(val);
        }
        setGauge(_gauge);
    };

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _gauge = { ...gauge };
        _gauge[`${name}`] = val;

        setGauge(_gauge);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedGauges || !selectedGauges.length} />
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" />
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

  

    const idBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Id</span>
                {rowData.gauge_id}
            </>
        );
    };

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Gauge Name</span>
                {rowData.name}
            </>
        );
    };

    const portBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Port</span>
                {rowData.port}
            </>
        );
    };

    const mfrBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Manufacturer</span>
                {rowData.manufacturer}
            </>
        );
    };

    const modelBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Model</span>
                {rowData.model}
            </>
        );
    };
    const serialBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Serial</span>
                {rowData.serial}
            </>
        );
    };
    // Create a custom body template for the 'device_id' column
    const deviceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Device</span>
                {rowData.device_id && devices.find((device) => device.device_id === rowData.device_id)?.name}
            </>
        );
    };
    
    const statusBodyTemplate = (rowData) => {

        const iconClassName = classNames("pi", {
            "pi-circle-on": rowData.status === 0,
            "pi-check": rowData.status === 1
        });
    
        return (
        <>
            <span className="p-column-title {iconClassName}">Status</span>
            {rowData.status === 0 ? "Active" : "Inactive"}
        </>
        );    
    };
    const createdDateBodyTemplate = (rowData) => {
        const formattedDate = formatDate(rowData.created_date);
        
        return (
            <>
            <span className="p-column-title">Created Date</span>
            {formattedDate}
            </>
        );
    };

   
    const actionBodyTemplate = (rowData) => {
        const buttonGroupStyle = {
            display: 'flex',      // Make the buttons display in a row
            justifyContent: 'space-between', // Distribute space between buttons
        };
    
        const buttonStyle = {
            flex: '1',            // Allow buttons to grow and share available space
            marginRight: '2px',   // Adjust spacing between buttons
        };
        return (
            <div className="actions" style={buttonGroupStyle}>
                <Button 
                    icon="pi pi-pencil" 
                    className="p-button-rounded p-button-success" 
                    style={buttonStyle}
                    onClick={() => editGauge(rowData)} />
                <Button 
                    icon="pi pi-trash" 
                    className="p-button-rounded p-button-warning" 
                    style={buttonStyle}
                    onClick={() => confirmDeleteGauge(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Gauges</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const gaugeDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveGauge} />
        </>
    );
    const deleteGaugeDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteGaugeDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteGauge} />
        </>
    );
    const deleteGaugesDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteGaugesDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedGauges} />
        </>
    );

    const onImageSelect = (event) => {
        const file = event.files[0]; // Assuming you allow only one image to be uploaded
        if (file) {

            // Optionally, display a preview of the selected image:
            const reader = new FileReader();
            reader.onload = (e) => {
                const imageDataUrl = e.target.result;

                // Encode the image data as Base64
                const base64Image = imageDataUrl.split(',')[1]; // Extract the Base64 part
    
                setGauge({ ...gauge, image_data: base64Image });
    
                // Optionally, display a preview of the selected image:
                setPreviewImage(imageDataUrl);

            };
            reader.readAsDataURL(file);
        }
    };
    
    
    

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={gauges}
                        selection={selectedGauges}
                        onSelectionChange={(e) => setSelectedGauges(e.value)}
                        dataKey="gauge_id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} gauges"
                        globalFilter={globalFilter}
                        emptyMessage="No gauges found."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="gauge_id" header="ID" body={idBodyTemplate}></Column>
                        <Column field="device_id" header="Device" body={deviceBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        {/* <Column field="device_id" header="Device ID" sortable body={nameBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column> */}
                        <Column field="port" header="Port" sortable body={portBodyTemplate} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                        <Column field="manufacturer" header="Manufacturer" sortable body={mfrBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="model" header="Model" sortable body={modelBodyTemplate} headerStyle={{ width: '15%', minWidth: '10rem' }}></Column>
                        <Column field="serial" header="Serial" sortable body={serialBodyTemplate} headerStyle={{ width: '15%', minWidth: '10rem' }}></Column>
                        <Column header="Image" body={imageBodyTemplate} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                        <Column field="status" header="Status" body={statusBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '8rem' }}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={gaugeDialog} style={{ width: '450px' }} header="Gauge Details" modal className="p-fluid" footer={gaugeDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="deviceId">Project</label>
                            <Dropdown
                                id="deviceId"
                                value={selectedDeviceId}
                                options={devices.map(device => ({ label: device.name, value: device.device_id }))}
                                onChange={(e) => setSelectedDeviceId(e.value)}
                                placeholder="Select a Device"
                            />
                        </div>
                        <div className="field">
                            <label htmlFor="port">Port</label>
                            <InputNumber  id="port" value={gauge.port} onChange={(e) => onInputNumberChange(e, 'port')} required  cols={20} />
                        </div>
                        <div className="field">
                            <label htmlFor="manufacturer">Manufacturer</label>
                            <InputText id="manufacturer" value={gauge.manufacturer} onChange={(e) => onInputChange(e, 'manufacturer')} required  cols={20} />
                        </div>
                        <div className="field">
                            <label htmlFor="model">Model</label>
                            <InputText id="model" value={gauge.model} onChange={(e) => onInputChange(e, 'model')} required  cols={20} />
                        </div>
                        <div className="field">
                            <label htmlFor="serial">serial</label>
                            <InputText id="serial" value={gauge.serial} onChange={(e) => onInputChange(e, 'serial')} required  cols={20} />
                        </div>
                        <div className="field">
                            <label htmlFor="status">Status</label>
                            <Dropdown id="status" value={status} options={statusOptions} onChange={(e) => onInputChange(e, 'status')} />
                        </div>
                        <div className="field">
                            <label htmlFor="created_date">Created Date</label>
                            <Calendar id="created_date" value={createdDate} onChange={(e) => onInputChange(e, 'created_date')} showIcon={true} dateFormat="dd/mm/yy" />
                        </div>
                        <div className="field">
                            <label htmlFor="image">Image</label>
                            <FileUpload
                                name="demo"
                                chooseLabel="Upload"
                                mode="basic"
                                auto
                                accept="image/jpeg"  // Accept only JPEG images
                                onSelect={(e) => onImageSelect(e)}
                            />
                            {previewImage && (
                                <div>
                                    <label>Selected Image Preview:</label>
                                    <img src={previewImage} alt="Selected Image" width="150" />
                                </div>
                            )}
                        </div>


                    </Dialog>

                    <Dialog visible={deleteGaugeDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteGaugeDialogFooter} onHide={hideDeleteGaugeDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {gauge && (
                                <span>
                                    Are you sure you want to delete <b>{gauge.gauge_id}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteGaugesDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteGaugesDialogFooter} onHide={hideDeleteGaugesDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {gauge && <span>Are you sure you want to delete the selected gauges?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default Gauge;
