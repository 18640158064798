import React, { useState, useEffect, useRef } from 'react';
import { Calendar, Dropdown, DataTable, Column, Toast, Button, Dialog, InputText, InputTextarea } from 'primereact';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { classNames } from 'primereact/utils';
import { SubscriptionService } from '../service/SubscriptionService';
import { SubscriptionTypeService } from '../service/SubscriptionTypeService';
import { CustomerService } from '../service/CustomerService';

const Subscription = () => {
    let emptySubscription = {
        subscription_id: null,
        customer_id: null,
        type_id: null,
        start_date: new Date(),
        end_date: new Date(),
        status: 0,
        // ... other fields as per your model
    };

    const [subscriptions, setSubscriptions] = useState([]);
    const [subscriptionDialog, setSubscriptionDialog] = useState(false);
    const [deleteSubscriptionDialog, setDeleteSubscriptionDialog] = useState(false);
    const [deleteSubscriptionsDialog, setDeleteSubscriptionsDialog] = useState(false);
    const [subscription, setSubscription] = useState(emptySubscription);
    const [selectedSubscriptions, setSelectedSubscriptions] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [status, setStatus] = useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);

    const [subscriptionTypes, setSubscriptionTypes] = useState([]);
    const [selectedSubscriptionTypeId, setSelectedSubscriptionTypeId] = useState(null);

    const [customers, setCustomers] = useState([]);
    const [selectedCustomerId, setSelectedCustomerId] = useState(null);
    

    const statusOptions = [
        {label: 'Open', value: 0},
        {label: 'Close', value: 1},
    ];

    useEffect(() => {
        const subscriptionService = new SubscriptionService();
        subscriptionService.getSubscriptions().then((response) => {
            setSubscriptions(response.data);
        });
        const customerService = new CustomerService();
        customerService.getCustomers2()
        .then(response => {
            console.log("API Response:", response); // Log to check the response format
            if (Array.isArray(response.data)) {
                setCustomers(response.data);
            } else {
                console.error("Expected an array from the API", response);
                // Handle the case when response is not an array
            }
        })
        .catch(error => {
            console.error("Error fetching customers:", error);
            // Handle any errors in fetching customers
        });
        // get all Subscription Type
        const subscriptionTypeService = new SubscriptionTypeService();
        subscriptionTypeService.getSubscriptionTypes()
        .then(response => {
            console.log("API Response:", response); // Log to check the response format
            if (Array.isArray(response.data)) {
                setSubscriptionTypes(response.data);
            } else {
                console.error("Expected an array from the API", response);
                // Handle the case when response is not an array
            }
        })
        .catch(error => {
            console.error("Error fetching Subscription type:", error);
            // Handle any errors in fetching subscription type
        });
    }, []);

    const openNew = () => {
        setSubscription(emptySubscription);
        setSelectedCustomerId(subscription.customer_id); // Set the selectedCustomerId state
        setSelectedSubscriptionTypeId(subscription.type_id)
        setStartDate(new Date(subscription.start_date));
        setEndDate(new Date(subscription.end_date));
        setSubmitted(false);
        setSubscriptionDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setSubscriptionDialog(false);
    };

    const hideDeleteSubscriptionDialog = () => {
        setDeleteSubscriptionDialog(false);
    };

    const hideDeleteSubscriptionsDialog = () => {
        setDeleteSubscriptionsDialog(false);
    };

    
    const saveSubscription = () => {
        setSubmitted(true);

        subscription.customer_id = selectedCustomerId;
        subscription.type_id = selectedSubscriptionTypeId;
        subscription.start_date=startDate;
        subscription.end_date=endDate;
            
        if (validateRecord(subscription)) {

            
            const subscriptionService = new SubscriptionService();
            // const isNewSubscription = !subscription.subscription_id;
            
            if (subscription.subscription_id==null) {
                // device.device_id = createId();
                // device.customer_id=localStorage.getItem('customerId');
                subscriptionService
                    .addSubscription(subscription)
                    .then((response) => {
                        // Handle the response for a new subscription
                        setSubscriptions([...subscriptions, subscription]);
                        toast.current.show({
                            severity: 'success',
                            summary: 'Successful',
                            detail: 'Subscription Created',
                            life: 3000,
                        });
                        setSubscriptionDialog(false);
                        setSubscription(emptySubscription);
                    })
                    .catch((error) => {
                        console.error('Error saving new Subscription:', error);
                    });
            } else {

                subscriptionService
                    .updateSubscription(subscription, subscription.subscription_id)
                    .then((response) => {
                        // Handle the response for an updated subscriber
                        const updatedSubscriptions = subscriptions.map((s) =>
                            s.subscription_id === response.data.subscription_id ? subscription : s
                        );
                        setSubscriptions(updatedSubscriptions);
                        toast.current.show({
                            severity: 'success',
                            summary: 'Successful',
                            detail: 'Subscription Updated',
                            life: 3000,
                        });
                        setSubscriptionDialog(false);
                        setSubscription(emptySubscription);
                    })
                    .catch((error) => {
                        console.error('Error updating Subscription:', error);
                    });
            }
        }
    };


    
    const validateRecord = (subscription) => {
        // Check if the subscription object is not null
        return subscription !== null && subscription !== undefined;
    };

    
    const editSubscription= (subscription) => {
        setSubscription({ ...subscription });
        setSelectedCustomerId(subscription.customer_id); // Set the selectedCustomerId state
        setSelectedSubscriptionTypeId(subscription.type_id)
        setStartDate(new Date(subscription.start_date));
        setEndDate(new Date(subscription.end_date));
        setSubscriptionDialog(true);
    };

    const confirmDeleteSubscription = (subscription) => {
        setSubscription(subscription);
        setDeleteSubscriptionDialog(true);
    };


    const deleteSubscription = () => {
        const subscriptionService = new SubscriptionService();
        subscriptionService
            .deleteSubscription(subscription.subscription_id)
            .then(() => {
                // Delete the subscription from the local state
                let updatedSubscriptions = subscriptions.filter((val) => val.subscription_id !== subscription.subscription_id);
                setSubscriptions(updatedSubscriptions);
                setDeleteSubscriptionDialog(false);
                setSubscription(emptySubscription);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Subscription Deleted', life: 3000 });
            })
            .catch((error) => {
                console.error('Error deleting Subscription:', error);
                // Handle any error in the API call
            });

    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < subscription.length; i++) {
            if (subscription[i].subscription_id === id) {
                index = i;
                break;
            }
        }

        return index;
    };


    
    const formatDate = (value) => {
        if (value) {
            const date = new Date(value); // Convert string to Date object
            return date.toLocaleDateString('en-US', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
        }
        return ''; // Return an empty string if value is not defined
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const confirmDeleteSelected = () => {
        setDeleteSubscriptionsDialog(true);
    };

    const deleteSelectedSubscriptions = () => {
        let _subscriptions = subscriptions.filter((val) => !selectedSubscriptions.includes(val));
        setSubscriptions(_subscriptions);
        setDeleteSubscriptionsDialog(false);
        setSelectedSubscriptions(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Subscriptions Deleted', life: 3000 });
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _subscription = { ...subscription };
        _subscription[`${name}`] = val;

        if (name === 'status') {
            setStatus(val);
        } else if (name === 'start_Date') {
            setStartDate(val);
        } else if (name === 'end_Date') {
            setEndDate(val);
        }
        setSubscription(_subscription);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedSubscriptions || !selectedSubscriptions.length} />
                </div>
            </React.Fragment>
        );

    };
    

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" />
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Subscription Name</span>
                {rowData.name}
            </>
        );
    };

    // DataTable columns
    const idBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">ID</span>
                {rowData.subscription_id}
            </>
        );
    };

    const customerBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Customer</span>
                {rowData.customer_id && customers.find((customer) => customer.customer_id === rowData.customer_id)?.name}
              
            </>
        );
    };

    const subscriptionTypeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Subs-Type Name</span>
                {rowData.type_id && subscriptionTypes.find((subscriptionType) => subscriptionType.type_id === rowData.type_id)?.type_name}
            </>
        );
    };

    const typeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Type ID</span>
                {rowData.type_id}
            </>
        );
    };
    const statusBodyTemplate = (rowData) => {
        
        const iconClassName = classNames("pi", {
            "pi-circle-on": rowData.status === 0,
            "pi-check": rowData.status === 1
        });
    
        return (
        <>
            <span className="p-column-title {iconClassName}">Status</span>
            {rowData.status === 0 ? "Open" : "Close"}
        </>
        );    
    };
    
    const startDateBodyTemplate = (rowData) => {
        const formattedDate = formatDate(rowData.start_date);
        
        return (
            <>
            <span className="p-column-title">Start Date</span>
            {formattedDate}
            </>
        );
    };

    const endDateBodyTemplate = (rowData) => {
        const formattedDate = formatDate(rowData.end_date);
        
        return (
            <>
            <span className="p-column-title">End Date</span>
            {formattedDate}
            </>
        );
    };
    const actionBodyTemplate = (rowData) => {
        const buttonGroupStyle = {
            display: 'flex',      // Make the buttons display in a row
            justifyContent: 'space-between', // Distribute space between buttons
        };
    
        const buttonStyle = {
            flex: '1',            // Allow buttons to grow and share available space
            marginRight: '2px',   // Adjust spacing between buttons
        };
        return (
            <div className="actions" style={buttonGroupStyle}>
                <Button 
                    icon="pi pi-pencil" 
                    className="p-button-rounded p-button-success" 
                    style={buttonStyle}
                    onClick={() => editSubscription(rowData)} />
                <Button 
                    icon="pi pi-trash" 
                    className="p-button-rounded p-button-warning" 
                    style={buttonStyle}
                    onClick={() => confirmDeleteSubscription(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Subscriptions</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );


    const subscriptionDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveSubscription} />
        </>
    );

    const deleteSubscriptionDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteSubscriptionDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSubscription} />
        </>
    );

    const deleteSubscriptionsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteSubscriptionsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedSubscriptions} />
        </>
    );
    

    // Table columns and templates

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    <DataTable 
                        ref={dt}
                        value={subscriptions} 
                        selection={selectedSubscriptions} 
                        onSelectionChange={(e) => setSelectedSubscriptions(e.value)}
                        dataKey="subscription_id" 
                        paginator 
                        rows={10} 
                        rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} subscriptions"
                        globalFilter={globalFilter}
                        emptyMessage="No Subscription found."
                        header={header}
                        >
                            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>

                            <Column field="subscription_id" header="ID" body={idBodyTemplate}></Column>
                            <Column field="customer_id" header="Customer Name" body={customerBodyTemplate} headerStyle={{ width: '30%', minWidth: '10rem' }}></Column>
                            <Column field="type_id" header="Subscription Type" body={subscriptionTypeBodyTemplate} headerStyle={{ width: '30%', minWidth: '10rem' }}></Column>
                            <Column field="status" header="status" body={statusBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '8rem' }}></Column>
                            <Column field="start_date" header="Start Date" sortable body={startDateBodyTemplate} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                            <Column field="end_date" header="End Date" body={endDateBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                            
                            <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>

            {/* Subscription Dialog */}
            <Dialog visible={subscriptionDialog} style={{ width: '450px' }} header="Subscription Details" modal className="p-fluid" footer={subscriptionDialogFooter} onHide={hideDialog}>
                {/* <div className="p-field">
                    <label htmlFor="customerName">Customer Name</label>
                    <InputText id="customerName" value={subscription.customer_name} onChange={(e) => onInputChange(e, 'customer_name')} disabled />
                </div> */}
                <div className="p-field">
                    <label htmlFor="customerId">Customer</label>
                    <Dropdown
                        id="customerId"
                        value={selectedCustomerId}
                        options={customers.map(customer => ({ label: customer.name, value: customer.customer_id }))}
                        onChange={(e) => setSelectedCustomerId(e.value)}
                        placeholder="Select a Customer"
                    />
                </div>
                <div className="p-field">
                    <label htmlFor="subsTypeId">Subscription Type</label>
                    <Dropdown
                        id="subsTypeId"
                        value={selectedSubscriptionTypeId}
                        options={subscriptionTypes.map(subscriptionType => ({ label: subscriptionType.type_name, value: subscriptionType.type_id }))}
                        onChange={(e) => setSelectedSubscriptionTypeId(e.value)}
                        placeholder="Select a Subs Type"
                    />
                </div>
                {/* <div className="p-field">
                    <label htmlFor="typeId">Type ID</label>
                    <InputText id="typeId" value={subscription.type_id} onChange={(e) => onInputChange(e, 'type_id')} />
                </div> */}
                <div className="p-field">
                    <label htmlFor="startDate">Start Date</label>
                    <Calendar id="startDate" value={startDate} onChange={(e) => setStartDate(e.value)} showIcon />
                </div>
                <div className="p-field">
                    <label htmlFor="endDate">End Date</label>
                    <Calendar id="endDate" value={endDate} onChange={(e) => setEndDate(e.value)} showIcon />
                </div>
                <div className="p-field">
                    <label htmlFor="status">Status</label>
                    <Dropdown id="status" value={status} options={statusOptions} onChange={(e) => onInputChange(e, 'status')}/>
                </div>
            </Dialog>

            {/* Delete Subscription Dialog */}
            <Dialog visible={deleteSubscriptionDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteSubscriptionDialogFooter} onHide={hideDeleteSubscriptionDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {subscription && <span>Are you sure you want to delete the subscription <b>{subscription.subscription_id}</b>?</span>}
                </div>
            </Dialog>

            <Dialog visible={deleteSubscriptionsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteSubscriptionsDialogFooter} onHide={hideDeleteSubscriptionsDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {subscription && <span>Are you sure you want to delete the selected subscriptions?</span>}
                </div>
            </Dialog>
        </div>
    );
};

export default Subscription;