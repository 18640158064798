import axios from 'axios';


// // Define the base URLs for the local and remote servers
const LOCAL_API_BASE_URL = 'http://localhost:5001';
const REMOTE_API_BASE_URL = 'https://api.mysensorhub.com';

// Define the server variable ('local' or 'remote')
const server = 'remote';  // Change this to 'remote' when needed

// Define the base URL based on the server variable
const API_BASE_URL = server === 'local' ? LOCAL_API_BASE_URL : REMOTE_API_BASE_URL;

export const login = async (email, password) => {
  try {
    const response = await axios.post(API_BASE_URL+'/logintoken', {
      "email":email,
      "password":password,
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};
