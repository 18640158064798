import axios from 'axios';


// // Define the base URLs for the local and remote servers
const LOCAL_API_BASE_URL = 'http://localhost:5001';
const REMOTE_API_BASE_URL = 'https://api.mysensorhub.com';

// Define the server variable ('local' or 'remote')
const server = 'remote';  // Change this to 'remote' when needed

// Define the base URL based on the server variable
const API_BASE_URL = server === 'local' ? LOCAL_API_BASE_URL : REMOTE_API_BASE_URL;


const apiClient = axios.create({
  baseURL: API_BASE_URL
});

// Response interceptor
apiClient.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // Handle token expiration (e.g., redirect to login, refresh token, etc.)
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);
export class GaugeDataService {
      // Helper function to get headers with Authorization token
  getAuthHeaders() {
    const accessToken = localStorage.getItem('accessToken');
    return {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    };
  }

    getGaugeData() {
        return axios.get('assets/demo/data/gaugedata.json').then((res) => res.data.data);
    }

    getGaugeDataByGaugeId(gauge_id) {
        const url = `/gauge-data/gauge/${gauge_id}`;
        return apiClient.get(url, this.getAuthHeaders());
    }
}
