import React from 'react';

const TermsOfUse = () => {
    return (
        <div className="p-grid p-justify-center">
            <div className="p-col-12 p-md-8">
                <div className="card">
                    <h1>Terms of Use</h1>
                    <p>Welcome to Sensor Hub, a cloud-based platform designed to help you store, manage, and analyze data from a variety of sensors in real-time. By using our services, you agree to be bound by the following terms and conditions.</p>

                    <h2>1. Acceptance of Terms</h2>
                    <p>By accessing or using Sensor Hub, you agree to comply with and be bound by these Terms of Use, as well as any policies, guidelines, or amendments that may be presented to you from time to time. If you do not agree to these terms, you should not use our services.</p>

                    <h2>2. Description of Service</h2>
                    <p>Sensor Hub is a subscription-based cloud service that allows users to store, manage, and analyze data from various sensors in real-time. Our platform offers tools for data visualization, project management, and report generation. The service is accessible via a web portal and is intended for both individual and business use.</p>

                    <h2>3. User Account and Security</h2>
                    <p>To access Sensor Hub, you must create an account. You are responsible for maintaining the confidentiality of your account information, including your password. You agree to notify us immediately of any unauthorized use of your account. We are not liable for any losses you incur due to unauthorized access to your account.</p>

                    <h2>4. Subscription and Billing</h2>
                    <p>Access to Sensor Hub requires a valid subscription. Subscription fees are billed monthly or annually, depending on your chosen plan. You agree to provide accurate and complete billing information. Failure to pay subscription fees may result in suspension or termination of your access to the service.</p>

                    <h2>5. Data Storage and Management</h2>
                    <p>Sensor Hub allows you to store and manage data collected from various sensors. While we strive to provide reliable and secure data storage, you acknowledge that data loss may occur, and you should maintain backup copies of your data. We are not liable for any data loss or corruption.</p>

                    <h2>6. Data Privacy and Security</h2>
                    <p>We are committed to protecting your data. All data stored in Sensor Hub is encrypted and access is restricted to authorized users only. However, you are responsible for ensuring that your use of the service complies with applicable data protection laws and regulations. We recommend that you review our Privacy Policy for more information on how we handle your data.</p>

                    <h2>7. Acceptable Use</h2>
                    <p>You agree not to use Sensor Hub for any illegal or unauthorized purposes, including but not limited to violating any intellectual property laws, distributing harmful software, or engaging in activities that disrupt the service. We reserve the right to suspend or terminate your account if you violate this policy.</p>

                    <h2>8. Service Availability and Uptime</h2>
                    <p>While we strive to maintain high availability, Sensor Hub is provided "as is" and "as available," without any warranties or guarantees of uptime. Scheduled maintenance and updates may result in temporary service interruptions. We are not liable for any damages resulting from service outages.</p>

                    <h2>9. Intellectual Property Rights</h2>
                    <p>All content, software, and technology used in Sensor Hub are the property of our company and are protected by intellectual property laws. You are granted a limited, non-exclusive, non-transferable license to use the service in accordance with these terms. You may not copy, modify, distribute, or reverse engineer any part of the service without our express permission.</p>

                    <h2>10. Limitation of Liability</h2>
                    <p>To the fullest extent permitted by law, we disclaim all warranties, express or implied, in connection with the service and your use thereof. We are not liable for any direct, indirect, incidental, consequential, or punitive damages arising out of your use of Sensor Hub.</p>

                    <h2>11. Indemnification</h2>
                    <p>You agree to indemnify and hold us harmless from any claims, damages, losses, liabilities, and expenses (including legal fees) arising from your use of Sensor Hub, your violation of these terms, or your infringement of any third-party rights.</p>

                    <h2>12. Termination</h2>
                    <p>We reserve the right to terminate or suspend your access to Sensor Hub at any time, with or without notice, for any reason, including but not limited to your breach of these terms. Upon termination, your right to use the service will cease immediately.</p>

                    <h2>13. Changes to the Terms</h2>
                    <p>We may update these Terms of Use from time to time. Any changes will be posted on this page, and your continued use of the service after such changes have been made will constitute your acceptance of the new terms.</p>

                    <h2>14. Governing Law</h2>
                    <p>These Terms of Use are governed by and construed in accordance with the laws of [Your Country/State], without regard to its conflict of law principles. You agree to submit to the exclusive jurisdiction of the courts located within [Your Jurisdiction] to resolve any legal matter arising from these terms.</p>

                    <h2>15. Contact Information</h2>
                    <p>If you have any questions about these Terms of Use, please contact us at support@sensorhub.com.</p>

                    <p>Thank you for choosing Sensor Hub. We hope you enjoy using our service!</p>
                </div>
            </div>
        </div>
    );
};

export default TermsOfUse;
