import axios from 'axios';

// // Define the base URLs for the local and remote servers
const LOCAL_API_BASE_URL = 'http://localhost:5001';
const REMOTE_API_BASE_URL = 'https://api.mysensorhub.com';

// Define the server variable ('local' or 'remote')
const server = 'remote';  // Change this to 'remote' when needed

// Define the base URL based on the server variable
const API_BASE_URL = server === 'local' ? LOCAL_API_BASE_URL : REMOTE_API_BASE_URL;

const apiClient = axios.create({
    baseURL: API_BASE_URL,
});

// Response interceptor
apiClient.interceptors.response.use(
    response => response,
    error => {
      if (error.response && error.response.status === 401) {
        // Handle token expiration (e.g., redirect to login, refresh token, etc.)
        window.location.href = '/login';
      }
      return Promise.reject(error);
    }
  );

export class SubscriptionTypeService {

    // Helper function to get headers with Authorization token
    getAuthHeaders() {
        const accessToken = localStorage.getItem('accessToken');
        return {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        };
    }

    getSubscriptionTypes() {
        const url = `/subscription-types`;
        return apiClient.get(url, this.getAuthHeaders());

    }

    getSubscriptionTypeById(typeId) {
        return apiClient.get(`/subscription-types/${typeId}`, this.getAuthHeaders());
    }

    addSubscriptionType(subscriptionType) {
        return apiClient.post('/subscription-types', subscriptionType, this.getAuthHeaders());
    }

    updateSubscriptionType(subscriptionType,typeId) {
        return apiClient.put(`/subscription-types/${typeId}`, subscriptionType, this.getAuthHeaders());
    }

    deleteSubscriptionType(typeId) {
        return apiClient.delete(`/subscription-types/${typeId}`, this.getAuthHeaders());
    }
}

