import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Chart } from 'primereact/chart';
import { classNames } from 'primereact/utils';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { Slider } from 'primereact/slider';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { ToggleButton } from 'primereact/togglebutton';
import { Rating } from 'primereact/rating';
import { CustomerService } from '../service/CustomerService';
import { ProductService } from '../service/ProductService';
import { GaugeDataService } from '../service/GaugeDataService';



    
const getLineData = (colorMode) => {
    const isLight = colorMode === 'light';
    const dataset1Color = isLight ? '#00ACC1' : '#4DD0E1';
    const dataset2Color = isLight ? '#FF9800' : '#FFB74D';

    return {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            {
                label: 'Temperature (°C)',
                data: [27, 28, 31, 29, 25, 20, 29],
                fill: false,
                backgroundColor: dataset1Color,
                borderColor: dataset1Color,
                tension: 0.4,
                yAxisID: 'y-axis-1'
            },
            {
                label: 'Humidity (%)',
                data: [81, 70, 100, 94, 86, 70, 90],
                fill: false,
                backgroundColor: dataset2Color,
                borderColor: dataset2Color,
                tension: 0.4,
                yAxisID: 'y-axis-2'
            }
        ]
    };
};

const options = {
    scales: {
      yAxes: [
        {
          type: 'linear',
          display: true,
          position: 'left',
          id: 'y-axis-1',
          label: 'Temperature'
        },
        {
          type: 'linear',
          display: true,
          position: 'right',
          id: 'y-axis-2',
          label: 'Humidity'
        }
      ]
    }
  };


const getLineData2 = (colorMode) => {
    const isLight = colorMode === 'light';
    const dataset1Color = isLight ? '#00ACC1' : '#4DD0E1';
    const dataset2Color = isLight ? '#FF9800' : '#FFB74D';

    return {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            {
                label: 'Displacement (mm)',
                data: [0.1, 0.2, 0.3, 0.5, 0.5, 0.4, 0.5],
                fill: false,
                backgroundColor: dataset1Color,
                borderColor: dataset1Color,
                tension: 0.4
            },
           
        ]
    };
};

const getColors = (colorMode) => {
    const isLight = colorMode === 'light';
    return {
        pinkColor: isLight ? '#EC407A' : '#F48FB1',
        purpleColor: isLight ? '#AB47BC' : '#CE93D8',
        deeppurpleColor: isLight ? '#7E57C2' : '#B39DDB',
        indigoColor: isLight ? '#5C6BC0' : '#9FA8DA',
        blueColor: isLight ? '#42A5F5' : '#90CAF9',
        lightblueColor: isLight ? '#29B6F6' : '#81D4FA',
        cyanColor: isLight ? '#00ACC1' : '#4DD0E1',
        tealColor: isLight ? '#26A69A' : '#80CBC4',
        greenColor: isLight ? '#66BB6A' : '#A5D6A7',
        lightgreenColor: isLight ? '#9CCC65' : '#C5E1A5',
        limeColor: isLight ? '#D4E157' : '#E6EE9C',
        yellowColor: isLight ? 'FFEE58' : '#FFF59D',
        amberColor: isLight ? '#FFCA28' : '#FFE082',
        orangeColor: isLight ? '#FFA726' : '#FFCC80',
        deeporangeColor: isLight ? '#FF7043' : '#FFAB91',
        brownColor: isLight ? '#8D6E63' : '#BCAAA4'
    };
};

const getLineChartOptions = () => {
    const textColor = getComputedStyle(document.body).getPropertyValue('--text-color') || 'rgba(0, 0, 0, 0.87)';
    const gridLinesColor = getComputedStyle(document.body).getPropertyValue('--divider-color') || 'rgba(160, 167, 181, .3)';
    const fontFamily = getComputedStyle(document.body).getPropertyValue('--font-family');

    return {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
            legend: {
                labels: {
                    font: {
                        family: fontFamily,
                    },
                    color: textColor
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    font: {
                        family: fontFamily,
                    },
                    color: textColor
                },
                grid: {
                    color: gridLinesColor
                }
            },
            y: {
                ticks: {
                    font: {
                        family: fontFamily,
                    },
                    color: textColor
                },
                grid: {
                    color: gridLinesColor
                }
            }
        }
    };
};


const getLineChartOptions2 = () => {
    const textColor = getComputedStyle(document.body).getPropertyValue('--text-color') || 'rgba(0, 0, 0, 0.87)';
    const gridLinesColor = getComputedStyle(document.body).getPropertyValue('--divider-color') || 'rgba(160, 167, 181, .3)';
    const fontFamily = getComputedStyle(document.body).getPropertyValue('--font-family');

    return {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
            legend: {
                labels: {
                    font: {
                        family: fontFamily,
                    },
                    color: textColor
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    font: {
                        family: fontFamily,
                    },
                    color: textColor
                },
                grid: {
                    color: gridLinesColor
                }
            },
            yAxes: [
                {
                  type: 'linear',
                  display: true,
                  position: 'left',
                  id: 'y-axis-1',
                  label: 'Temperature'
                },
                {
                  type: 'linear',
                  display: true,
                  position: 'right',
                  id: 'y-axis-2',
                  label: 'Humidity'
                }
            ]
        }
    };
};

let lineChartOptions;
let lineChartOptions2;

const DataLayoutGauge = (props) => {
    const [gaugedata, setGaugeData] = useState(null);
    const [customers1, setCustomers1] = useState(null);
    const [customers2, setCustomers2] = useState([]);
    const [customers3, setCustomers3] = useState([]);
    const [filters1, setFilters1] = useState(null);
    const [loading1, setLoading1] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [idFrozen, setIdFrozen] = useState(false);
    const [products, setProducts] = useState([]);
    const [expandedRows, setExpandedRows] = useState(null);

    const [dropdownItem, setDropdownItem] = useState(null);
    const [lineData, setLineData] = useState(getLineData(props.colorMode));
    const [lineData2, setLineData2] = useState(getLineData2(props.colorMode));

    const representatives = [
        { name: 'Amy Elsner', image: 'amyelsner.png' },
        { name: 'Anna Fali', image: 'annafali.png' },
        { name: 'Asiya Javayant', image: 'asiyajavayant.png' },
        { name: 'Bernardo Dominic', image: 'bernardodominic.png' },
        { name: 'Elwin Sharvill', image: 'elwinsharvill.png' },
        { name: 'Ioni Bowcher', image: 'ionibowcher.png' },
        { name: 'Ivan Magalhaes', image: 'ivanmagalhaes.png' },
        { name: 'Onyama Limba', image: 'onyamalimba.png' },
        { name: 'Stephen Shaw', image: 'stephenshaw.png' },
        { name: 'XuXue Feng', image: 'xuxuefeng.png' }
    ];

    const statuses = ['unqualified', 'qualified', 'new', 'negotiation', 'renewal', 'proposal'];

    const customerService = new CustomerService();
    const productService = new ProductService();
    const gaugeDataService = new GaugeDataService();
    
    const updateCharts = () => {
        setLineData(getLineData(props.colorMode));
        setLineData2(getLineData2(props.colorMode));

        lineChartOptions = getLineChartOptions();
        lineChartOptions2 = getLineChartOptions2();

    };
    
    useEffect(() => {
        updateCharts();

        setLoading2(true);
        gaugeDataService.getGaugeData().then((data) => {
            setGaugeData(getGaugeData(data));
            setLoading1(false);
        });
        customerService.getCustomersLarge().then((data) => {
            setCustomers1(getCustomers(data));
            setLoading1(false);
        });
        customerService.getCustomersLarge().then((data) => {
            setCustomers2(getCustomers(data));
            setLoading2(false);
        });
        customerService.getCustomersMedium().then((data) => setCustomers3(data));
        productService.getProductsWithOrdersSmall().then((data) => setProducts(data));
        gaugeDataService.getGaugeData().then((data) => setGaugeData(data));
        initFilters1();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.isNewThemeLoaded) {
            updateCharts();
            props.onNewThemeChange(false);
            setLoading2(true);
            gaugeDataService.getGaugeData().then((data) => {
                setGaugeData(getGaugeData(data));
                setLoading1(false);
            });
            customerService.getCustomersLarge().then((data) => {
                setCustomers1(getCustomers(data));
                setLoading1(false);
            });
            customerService.getCustomersLarge().then((data) => {
                setCustomers2(getCustomers(data));
                setLoading2(false);
            });
            customerService.getCustomersMedium().then((data) => setCustomers3(data));
            productService.getProductsWithOrdersSmall().then((data) => setProducts(data));
            gaugeDataService.getGaugeData().then((data) => setGaugeData(data));
            
            initFilters1();
        }
    }, [props.isNewThemeLoaded, props.onNewThemeChange]); // eslint-disable-line react-hooks/exhaustive-deps

    const getCustomers = (data) => {
        return [...(data || [])].map((d) => {
            d.date = new Date(d.date);
            return d;
        });
    };

    const getGaugeData = (data) => {
        return [...(data || [])].map((d) => {
            d.date = new Date(d.date);
            return d;
        });
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    };

    const initFilters1 = () => {
        setFilters1({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            equip_id: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            gauge_id: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            
            temperature: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            humidity: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            displacement: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] }
        });
    };

    const countryBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <img alt="flag" src="assets/demo/flags/flag_placeholder.png" className={`flag flag-${rowData.country.code}`} width={30} />
                <span style={{ marginLeft: '.5em', verticalAlign: 'middle' }} className="image-text">
                    {rowData.country.name}
                </span>
            </React.Fragment>
        );
    };

    const filterClearTemplate = (options) => {
        return <Button type="button" icon="pi pi-times" onClick={options.filterClearCallback} className="p-button-secondary"></Button>;
    };

    const filterApplyTemplate = (options) => {
        return <Button type="button" icon="pi pi-check" onClick={options.filterApplyCallback} className="p-button-success"></Button>;
    };

    const representativeBodyTemplate = (rowData) => {
        const representative = rowData.representative;
        return (
            <React.Fragment>
                <img
                    alt={representative.name}
                    src={`assets/demo/images/avatar/${representative.image}`}
                    onError={(e) => (e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png')}
                    width={32}
                    style={{ verticalAlign: 'middle' }}
                />
                <span style={{ marginLeft: '.5em', verticalAlign: 'middle' }} className="image-text">
                    {representative.name}
                </span>
            </React.Fragment>
        );
    };

    const representativeFilterTemplate = (options) => {
        return (
            <>
                <div className="mb-3 text-bold">Agent Picker</div>
                <MultiSelect value={options.value} options={representatives} itemTemplate={representativesItemTemplate} onChange={(e) => options.filterCallback(e.value)} optionLabel="name" placeholder="Any" className="p-column-filter" />
            </>
        );
    };

    const representativesItemTemplate = (option) => {
        return (
            <div className="p-multiselect-representative-option">
                <img alt={option.name} src={`assets/demo/images/avatar/${option.image}`} width={32} style={{ verticalAlign: 'middle' }} />
                <span style={{ marginLeft: '.5em', verticalAlign: 'middle' }} className="image-text">
                    {option.name}
                </span>
            </div>
        );
    };

    const dateBodyTemplate = (rowData) => {
        return formatDate(new Date(rowData.date));
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
    };

    const balanceBodyTemplate = (rowData) => {
        return formatCurrency(rowData.balance);
    };

    const balanceFilterTemplate = (options) => {
        return <InputNumber value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} mode="currency" currency="USD" locale="en-US" />;
    };

    const statusBodyTemplate = (rowData) => {
        return <span className={`customer-badge status-${rowData.status}`}>{rowData.status}</span>;
    };

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate} placeholder="Select a Status" className="p-column-filter" showClear />;
    };

    const statusItemTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    };

    const activityBodyTemplate = (rowData) => {
        return <ProgressBar value={rowData.activity} showValue={false} style={{ height: '.5rem' }}></ProgressBar>;
    };

    const activityFilterTemplate = (options) => {
        return (
            <React.Fragment>
                <Slider value={options.value} onChange={(e) => options.filterCallback(e.value)} range className="m-3"></Slider>
                <div className="flex align-items-center justify-content-between px-2">
                    <span>{options.value ? options.value[0] : 0}</span>
                    <span>{options.value ? options.value[1] : 100}</span>
                </div>
            </React.Fragment>
        );
    };

    const verifiedBodyTemplate = (rowData) => {
        return <i className={classNames('pi', { 'text-green-500 pi-check-circle': rowData.verified, 'text-pink-500 pi-times-circle': !rowData.verified })}></i>;
    };

    const verifiedFilterTemplate = (options) => {
        return <TriStateCheckbox value={options.value} onChange={(e) => options.filterCallback(e.value)} />;
    };

    const expandAll = () => {
        let _expandedRows = {};
        products.forEach((p) => (_expandedRows[`${p.id}`] = true));

        setExpandedRows(_expandedRows);
    };

    const collapseAll = () => {
        setExpandedRows(null);
    };

    const amountBodyTemplate = (rowData) => {
        return formatCurrency(rowData.amount);
    };

    const statusOrderBodyTemplate = (rowData) => {
        return <span className={`order-badge order-${rowData.status.toLowerCase()}`}>{rowData.status}</span>;
    };

    const searchBodyTemplate = () => {
        return <Button icon="pi pi-search" />;
    };

    const imageBodyTemplate = (rowData) => {
        return <img src={`assets/demo/images/product/${rowData.image}`} onError={(e) => (e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png')} alt={rowData.image} className="shadow-2" width={100} />;
    };

    const priceBodyTemplate = (rowData) => {
        return formatCurrency(rowData.price);
    };

    const ratingBodyTemplate = (rowData) => {
        return <Rating value={rowData.rating} readOnly cancel={false} />;
    };

    const statusBodyTemplate2 = (rowData) => {
        return <span className={`product-badge status-${rowData.inventoryStatus.toLowerCase()}`}>{rowData.inventoryStatus}</span>;
    };

    const rowExpansionTemplate = (data) => {
        return (
            <div className="orders-subtable">
                <h5>Orders for {data.name}</h5>
                <DataTable value={data.orders} responsiveLayout="scroll">
                    <Column field="id" header="Id" sortable></Column>
                    <Column field="customer" header="Customer" sortable></Column>
                    <Column field="date" header="Date" sortable></Column>
                    <Column field="amount" header="Amount" body={amountBodyTemplate} sortable></Column>
                    <Column field="status" header="Status" body={statusOrderBodyTemplate} sortable></Column>
                    <Column headerStyle={{ width: '4rem' }} body={searchBodyTemplate}></Column>
                </DataTable>
            </div>
        );
    };

    const header = (
        <div className="table-header-container">
            <Button icon="pi pi-plus" label="Expand All" onClick={expandAll} className="mr-2 mb-2" />
            <Button icon="pi pi-minus" label="Collapse All" onClick={collapseAll} className="mb-2" />
        </div>
    );

    const headerTemplate = (data) => {
        return (
            <React.Fragment>
                <img alt={data.representative.name} src={`assets/demo/images/avatar/${data.representative.image}`} width="32" style={{ verticalAlign: 'middle' }} />
                <span className="image-text font-bold ml-2">{data.representative.name}</span>
            </React.Fragment>
        );
    };

    const footerTemplate = (data) => {
        return (
            <React.Fragment>
                <td colSpan="4" style={{ textAlign: 'right' }} className="text-bold pr-6">
                    Total Customers
                </td>
                <td>{calculateCustomerTotal(data.representative.name)}</td>
            </React.Fragment>
        );
    };

    const calculateCustomerTotal = (name) => {
        let total = 0;

        if (customers3) {
            for (let customer of customers3) {
                if (customer.representative.name === name) {
                    total++;
                }
            }
        }

        return total;
    };

    const dropdownItems = [
        { name: 'Option 1', code: 'Option 1' },
        { name: 'Option 2', code: 'Option 2' },
        { name: 'Option 3', code: 'Option 3' }
    ];

    return (
        <div className="grid p-fluid">
            <div className="col-12">
                <div>
                    <h5>Gauges Information</h5>
                    <div className="card p-fluid formgrid grid">
                        <div  className="field col-12 md:col-4">
                            <label htmlFor="projname">Project Name</label>
                            <InputText id="projname" type="text" ></InputText>
                        </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="engname">User</label>
                            <InputText id="engname" type="text" />
                        </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="startdate">Initial Project Date</label>
                            <InputText id="startdate" type="text" />
                        </div>
                        <div  className="field col-12 md:col-3">
                            <label htmlFor="datalogname">DataLogger ID</label>
                            <InputText id="datalogname" type="text" ></InputText>
                        </div>
                        <div className="field col-12 md:col-3">
                            <label htmlFor="gaugename">Gauge ID</label>
                            <InputText id="gaugename" type="text" />
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="location">Location</label>
                            <InputText id="location" type="text" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12 lg:col-6">
                <div className="card">
                    <h5 className="centerText">Weather Chart</h5>
                    <Chart type="line" data={lineData} options={lineChartOptions2}></Chart>
                </div>
            </div>
            <div className="col-12 lg:col-6">
                <div className="card">
                    <h5 className="centerText">Displacement Chart</h5>
                    <Chart type="line" data={lineData2} options={lineChartOptions}></Chart>
                </div>
            </div>

            <div className="col-12">
                <div className="card">
                    <h5>Data Received</h5>
                    <DataTable
                        value={gaugedata}
                        paginator
                        className="p-datatable-gridlines"
                        showGridlines
                        rows={10}
                        dataKey="id"
                        filters={filters1}
                        filterDisplay="menu"
                        loading={loading1}
                        responsiveLayout="scroll"
                        emptyMessage="No Gauge found."
                    >
                        <Column field="equip_id" header="Datalogger ID" filter filterPlaceholder="Search by equip_id" style={{ minWidth: '12rem' }} />
                        <Column field="gauge_id" header="Gauge Id" filter filterPlaceholder="Search by gauge_id" style={{ minWidth: '12rem' }} />
                        <Column header="Date" filterField="DateTime" dataType="date" style={{ minWidth: '10rem' }} body={dateBodyTemplate} filter filterElement={dateFilterTemplate} />
                        <Column field="temperature" header="Temperature(°C)" filter filterPlaceholder="Search by name" style={{ minWidth: '12rem' }} />
                        <Column field="humidity" header="Humidity(%)" filter filterPlaceholder="Search by name" style={{ minWidth: '12rem' }} />
                        <Column field="displacement" header="Displacement(mm)" filter filterPlaceholder="Search by name" style={{ minWidth: '12rem' }} />
                    </DataTable>
                </div>
            </div>

        </div>

            

    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode && prevProps.isNewThemeLoaded === nextProps.isNewThemeLoaded && prevProps.onNewThemeChange === nextProps.onNewThemeChange;
};
export default React.memo(DataLayoutGauge, comparisonFn);
