import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import { Card } from 'primereact/card';
import { ProjectService } from '../service/ProjectService';
import { DeviceService } from '../service/DeviceService';
import { GaugeService } from '../service/GaugeService';
import { useNavigate } from 'react-router-dom';

const DashboardProject = () => {
    const { projectId } = useParams(); // Assuming you're using react-router for navigation
    const [project, setProject] = useState(null);
    const [devices, setDevices] = useState([]);
    const [expandedRows, setExpandedRows] = useState(null);
    const [loading, setLoading] = useState(true);

    const projectService = new ProjectService();
    const deviceService = new DeviceService();
    const gaugeService = new GaugeService();
    const navigate = useNavigate();

    useEffect(() => {
        console.log('DashboardProject loaded with projectId:', projectId); // Log to check component load
        fetchProjectDetails(projectId);
    }, [projectId]);

    const fetchProjectDetails = async (projectId) => {
        console.log('Fetching project details for projectId:', projectId); // Log to trace function call
        try {
            const projectResponse = await projectService.getProjectById(projectId);
            console.log('Project data fetched:', projectResponse.data); // Log fetched project data
            const projectData = projectResponse.data;
            setProject(projectData);

            const devicesResponse = await deviceService.getDevicesForProject(projectId);
            const devicesData = devicesResponse.data;
            console.log('Devices data fetched:', devicesData); // Log fetched devices data

            const devicesWithGauges = await Promise.all(
                devicesData.map(async (device) => {
                    const gaugesResponse = await gaugeService.getGaugesForDevice(device.device_id);
                    console.log('Gauges data fetched for device:', device.device_id, gaugesResponse.data); // Log fetched gauges data
                    return {
                        ...device,
                        gauges: gaugesResponse.data,
                    };
                })
            );

            setDevices(devicesWithGauges);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching project data:', error); // Log error
            setLoading(false);
        }
    };

    const renderHeader = () => {
        return (
            <div className="table-header-container">
                <Button icon="pi pi-plus" label="Expand All" onClick={expandAll} className="mr-2 mb-2" />
                <Button icon="pi pi-minus" label="Collapse All" onClick={collapseAll} className="mb-2" />
            </div>
        );
    };

    const expandAll = () => {
        let _expandedRows = {};
        devices.forEach((device) => (_expandedRows[`${device.device_id}`] = true));
        setExpandedRows(_expandedRows);
    };

    const collapseAll = () => {
        setExpandedRows(null);
    };

    const gaugeTemplate = (rowData) => {
        return (
            <div className="gauges-subtable">
                <h5>Gauges for {rowData.name}</h5>
                <DataTable value={rowData.gauges} responsiveLayout="scroll">
                    <Column field="gauge_id" header="Gauge ID" sortable />
                    <Column field="model" header="Model" sortable />
                    <Column field="serial" header="Serial" sortable />
                    <Column field="manufacturer" header="Manufacturer" sortable />
                    <Column field="status" header="Status" />
                    <Column field="actual_value" header="Actual Value" />
                    {/* New column for Gauge Detail button */}
                    <Column
                        header="Actions"
                        body={(data) => (
                            <Button
                                label="View Details"
                                icon="pi pi-search"
                                className="p-button-info"
                                onClick={() => navigate(`/pages/gaugeDetail/${data.gauge_id}`)}
                            />
                        )}
                    />
                </DataTable>
            </div>
        );
    };

    const renderProjectDetails = () => {
        if (!project) return null;
        return (
            <div className="grid" style={{ alignItems: 'center' }}>
                <div className="col-12 md:col-4" style={{ paddingRight: '1rem' }}>
                    {project.image_data ? (
                        <img
                            src={`data:image/png;base64,${project.image_data}`}
                            alt="Project Image"
                            className="project-image"
                            style={{ width: '100%', borderRadius: '8px' }}
                        />
                    ) : (
                        <p>No Image Available</p>
                    )}
                </div>
                <div className="col-12 md:col-8">
                    <Card title={project.name}>
                        <p><strong>Field Engineer:</strong> {project.field_engineer}</p>
                        <p><strong>Address:</strong> {project.address}</p>
                        <p><strong>Status:</strong> {project.status === 0 ? 'Open' : 'Close'}</p>
                        <p><strong>Created Date:</strong> {new Date(project.created_Date).toLocaleDateString()}</p>
                        {project.closed_Date && <p><strong>Closed Date:</strong> {new Date(project.closed_Date).toLocaleDateString()}</p>}
                    </Card>
                </div>
            </div>
        );
    };


    return (
        <div className="grid">
            <div className="col-12">

                <Panel header="Project Details">
                    {renderProjectDetails()}
                </Panel>
            </div>

            <div className="col-12">
                <Panel header="Devices">
                    <DataTable
                        value={devices}
                        expandedRows={expandedRows}
                        onRowToggle={(e) => setExpandedRows(e.data)}
                        rowExpansionTemplate={gaugeTemplate}
                        dataKey="device_id"
                        responsiveLayout="scroll"
                        header={renderHeader()}
                        loading={loading}
                    >
                        <Column expander style={{ width: '3em' }} />
                        <Column field="name" header="Device Name" sortable />
                        <Column field="model" header="Model" sortable />
                        <Column field="serial" header="Serial" sortable />
                        <Column field="address" header="Address" />
                        <Column field="status" header="Status" />
                        <Column field="created_Date" header="Created Date" body={(rowData) => new Date(rowData.created_Date).toLocaleDateString()} />
                    </DataTable>
                </Panel>
            </div>
        </div>
    );
};

export default DashboardProject;
