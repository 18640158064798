import React, { useEffect, useState, useRef } from 'react';
import { PDFDownloadLink, Page, Text, View, StyleSheet, Document, Font, Image } from '@react-pdf/renderer';
import html2canvas from 'html2canvas';
import { useParams } from 'react-router-dom';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Chart } from 'primereact/chart';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { GaugeDataService } from '../service/GaugeDataService';
import { ProjectService } from '../service/ProjectService';
import { DeviceService } from '../service/DeviceService';
import { GaugeService } from '../service/GaugeService';
import * as XLSX from 'xlsx';
import { CustomerService } from '../service/CustomerService';
import { UserService } from '../service/UserService';
import { CountryService } from '../service/CountryService';
import { StateService } from '../service/StateService';


// Register fonts at the top level
Font.register({
    family: 'Roboto-Regular',
    src: require('../assets/fonts/Roboto-Regular.ttf'),
});
  
Font.register({
    family: 'Roboto-Bold',
    src: require('../assets/fonts/Roboto-Bold.ttf'),
});

const GaugeDetail = () => {
    let emptyUser = {
        id: null,
        customer_id: 0,
        first_name: '',
        last_name: '',
        email: '',
        password: '', // Consider handling password securely
        phone: '',
        rol: 0,
        created: new Date() // Or null, depending on how you handle dates
    };
    let emptyCustomer = {
        customer_id: null,
        name: '',
        address1: '',
        address2: '',
        city: '',
        state_id: null, // You might need to handle state and country IDs carefully
        country_id: null,
        zipcode: '',
        image_data: null, // If you're handling image uploads
        created_at: new Date() // Or null, depending on your date handling
    };
    
    const [user, setUser] = useState(emptyUser);
    const [customer, setCustomer] = useState(emptyCustomer);

    const { gaugeId } = useParams(); // Assuming you're using react-router for navigation
    const [gaugeData, setGaugeData] = useState([]);
    const [chartData, setChartData] = useState({ weather: {}, displacement: {} }); // New state for chart data
    const [project, setProject] = useState({});
    const [device, setDevice] = useState({});
    const [gauge, setGauge] = useState({});
    const [loading, setLoading] = useState(true);
    const [chartImages, setChartImages] = useState({ weather: null, displacement: null });

    const weatherChartRef = useRef(null);
    const displacementChartRef = useRef(null);

    const userId = localStorage.getItem('userId');
    const customerId = localStorage.getItem('customerId'); // Retrieve customer_id from local storage
    const userService = new UserService();
    const customerService = new CustomerService();
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);

    const countryService = new CountryService();
    const stateService = new StateService();


    const gaugeDataService = new GaugeDataService();
    const projectService = new ProjectService();
    const deviceService = new DeviceService();
    const gaugeService = new GaugeService();

    useEffect(() => {
        console.log('GaugeDetail loaded with gaugeId:', gaugeId); // Log to check component load
        if (gaugeId) {
            fetchData();
        }
    }, [gaugeId]);

    useEffect(() => {
        // Fetch countries
        countryService.getAllCountries()
        .then(response => {
            setCountries(response.data);
        })
        .catch(error => {
            console.error('Error fetching countries:', error);
        });
        
    }, []); // Empty dependency array means this will run once on mount
    
    useEffect(() => {
        // Fetch states
        stateService.getAllStates()
        .then(response => {
            setStates(response.data);
        })
        .catch(error => {
            console.error('Error fetching states:', error);
        });
    }, []); // This will also run once on mount
    
    useEffect(() => {
        // Fetch user data
        if (userId) {
            userService.getUserById(userId)
                .then(response => {
                    setUser(response.data);
                })
                .catch(error => {
                    console.error('Error fetching user data:', error);
                });
        }
    }, [userId]); // Only re-run if userId changes

    useEffect(() => {
        // Fetch customer data
        if (customerId) {
            customerService.getCustomerById(customerId)
                .then(response => {
                    setCustomer(response.data);
                    // When the country is fetched, then fetch states for the country
                    if (response.data.country_id && countries.length > 0) {
                        setSelectedCountry(countries.find(c => c.id === response.data.country_id));
                        fetchStatesForCountry(response.data.country_id);
                    }
                })
                .catch(error => {
                    console.error('Error fetching customer data:', error);
                });
        }
    }, [customerId, countries]); // Only re-run if customerId or countries changes
    
    const fetchStatesForCountry = (countryId) => {
        // Assuming StateService has a method to fetch states by countryId
        StateService.getStatesByCountryId(countryId)
            .then(response => {
                setStates(response.data);
                // Set the initial selected state based on customer data
                const customerState = response.data.find(state => state.id === customer.state_id);
                setSelectedState(customerState);
            })
            .catch(error => console.error('Error fetching states:', error));
    };

    const fetchData = async () => {
        try {
            setLoading(true);

            // Fetch gauge data
            const gaugeDataResult = await gaugeDataService.getGaugeDataByGaugeId(gaugeId);
            setGaugeData(gaugeDataResult.data);

            // Ensure gaugeDataResult.data is an array
            const gaugeDataArray = Array.isArray(gaugeDataResult.data) ? gaugeDataResult.data : [];

            // Prepare data for Weather and Displacement Charts
            const temperatureData = [];
            const humidityData = [];
            const displacementData = [];
            const labels = [];

            gaugeDataArray.forEach((data) => {
                labels.push(new Date(data.datetime).toLocaleDateString()); // Format datetime for X-axis
                temperatureData.push(data.temperature);
                humidityData.push(data.humidity);
                displacementData.push(data.value);
            });

            setChartData({
                weather: {
                    labels,
                    datasets: [
                        {
                            label: 'Temperature (°C)',
                            data: temperatureData,
                            borderColor: '#FF6384',
                            backgroundColor: '#FF6384',
                            fill: false,
                            tension: 0.4,
                        },
                        {
                            label: 'Humidity (%)',
                            data: humidityData,
                            borderColor: '#36A2EB',
                            backgroundColor: '#36A2EB',
                            fill: false,
                            tension: 0.4,
                        },
                    ],
                },
                displacement: {
                    labels,
                    datasets: [
                        {
                            label: 'Displacement (mm)',
                            data: displacementData,
                            borderColor: '#FFCE56',
                            backgroundColor: '#FFCE56',
                            fill: false,
                            tension: 0.4,
                        },
                    ],
                },
            });

            // Fetch gauge details
            const gaugeResult = await gaugeService.getGaugeById(gaugeId);
            setGauge(gaugeResult.data);

            // Fetch device details
            const deviceResult = await deviceService.getDeviceById(gaugeResult.data.device_id);
            setDevice(deviceResult.data);

            // Fetch project details
            const projectResult = await projectService.getProjectById(deviceResult.data.project_id);
            setProject(projectResult.data);

            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        }
    };

    const getLineChartOptions = () => {
        const textColor = getComputedStyle(document.body).getPropertyValue('--text-color') || 'rgba(0, 0, 0, 0.87)';
        const gridLinesColor = getComputedStyle(document.body).getPropertyValue('--divider-color') || 'rgba(160, 167, 181, .3)';
        const fontFamily = getComputedStyle(document.body).getPropertyValue('--font-family');

        return {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    labels: {
                        font: { family: fontFamily },
                        color: textColor
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        font: { family: fontFamily },
                        color: textColor
                    },
                    grid: { color: gridLinesColor }
                },
                y: {
                    ticks: {
                        font: { family: fontFamily },
                        color: textColor
                    },
                    grid: { color: gridLinesColor }
                }
            }
        };
    };

    const captureChartsAsImages = () => {
        setTimeout(() => {
            const weatherChartElement = document.getElementById('weather-chart');
            const displacementChartElement = document.getElementById('displacement-chart');
        
            if (weatherChartElement) {
                html2canvas(weatherChartElement).then((canvas) => {
                    setChartImages((prevImages) => ({ ...prevImages, weather: canvas.toDataURL('image/png') }));
                }).catch((error) => console.error('Error capturing weather chart:', error));
            } else {
                console.error('Weather chart element not found.');
            }
        
            if (displacementChartElement) {
                html2canvas(displacementChartElement).then((canvas) => {
                    setChartImages((prevImages) => ({ ...prevImages, displacement: canvas.toDataURL('image/png') }));
                }).catch((error) => console.error('Error capturing displacement chart:', error));
            } else {
                console.error('Displacement chart element not found.');
            }
        }, 1500); // Delay of 500ms to allow charts to render
    };

    useEffect(() => {
        if (!loading && chartData.weather.datasets && chartData.displacement.datasets) {
            captureChartsAsImages();
        }
    }, [loading, chartData]); // Runs when loading is false and chartData changes
    const exportToPdf = () => {
        const styles = StyleSheet.create({
            page: { padding: 30 },
            section: { marginBottom: 10 },
            title: { fontSize: 14, marginBottom: 10 },
            reportTitle: { fontSize: 18, marginBottom: 20, textAlign: 'center', fontWeight: 'bold' }, // Style for report title
            textRow: { flexDirection: 'row', marginBottom: 5 },
            text: { fontSize: 10, fontFamily: 'Roboto-Regular' },
            boldText: { fontSize: 10, fontFamily: 'Roboto-Bold' },
            row: { flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20 },
            column: { width: '30%', paddingRight: 10 },
            tableRow: {
                flexDirection: 'row',
                borderBottomWidth: 1,
                borderBottomColor: '#000',
                borderBottomStyle: 'solid',
                padding: 5,
            },
            tableCol: {
                width: '25%',
                fontSize: 10,
            },
            tableHeader: {
                fontWeight: 'bold',
                backgroundColor: '#f0f0f0',
                padding: 5,
            },
            chartImage: { 
                width: '100%',
                height: 150,
                marginBottom: 10,
            },
            customerImage: { 
                width: 60, 
                height: 60, 
                marginRight: 10 
            },
        });
    
        // Format the base64 image data for the PDF
        const customerImageSrc = customer.image_data ? `data:image/png;base64,${customer.image_data}` : null;
    
        const PdfDocument = (
            <Document>
                {/* First Page */}
                <Page style={styles.page}>
                    {/* Customer Information Section */}
                    <View style={styles.customerInfo}>
                        {customerImageSrc && (
                            <Image
                                style={styles.customerImage}
                                src={customerImageSrc} // Assuming image_data is a base64 or URL
                            />
                        )}
                        <View>
                            <Text style={styles.title}>Customer: {customer.name}</Text>
                            <Text style={styles.text}>Address: {customer.address1}, {customer.city}</Text>
                        </View>
                    </View>
    
                    {/* Report Title */}
                    <Text style={styles.reportTitle}>Gauge Detail Report</Text>
    
                    {/* Three-column layout for Project, Device, and Gauge Details */}
                    <View style={styles.row}>
                        <View style={styles.column}>
                            <Text style={styles.title}>Project Details</Text>
                            <View style={styles.textRow}>
                                <Text style={styles.boldText}>Name: </Text>
                                <Text style={styles.text}>{project.name}</Text>
                            </View>
                            <View style={styles.textRow}>
                                <Text style={styles.boldText}>Address: </Text>
                                <Text style={styles.text}>{project.address}</Text>
                            </View>
                            <View style={styles.textRow}>
                                <Text style={styles.boldText}>Field Engineer: </Text>
                                <Text style={styles.text}>{project.field_engineer}</Text>
                            </View>
                            <View style={styles.textRow}>
                                <Text style={styles.boldText}>Status: </Text>
                                <Text style={styles.text}>{project.status === 0 ? 'Open' : 'Closed'}</Text>
                            </View>
                            
                        </View>
                        <View style={styles.column}>
                            <Text style={styles.title}>Device Details</Text>
                            <View style={styles.textRow}>
                                <Text style={styles.boldText}>Device ID: </Text>
                                <Text style={styles.text}>{device.device_id}</Text>
                            </View>
                            <View style={styles.textRow}>
                                <Text style={styles.boldText}>Model: </Text>
                                <Text style={styles.text}>{device.model}</Text>
                            </View>
                            <View style={styles.textRow}>
                                <Text style={styles.boldText}>Serial:</Text>
                                <Text style={styles.text}>{device.serial}</Text>
                            </View>
                            <View style={styles.textRow}>
                                <Text style={styles.boldText}>Status: </Text>
                                <Text style={styles.text}>{device.status === 0 ? 'Active' : 'Inactive'}</Text>
                            </View>
                           
                        </View>
                        <View style={styles.column}>
                            <Text style={styles.title}>Gauge Details</Text>
                            <View style={styles.textRow}>
                                <Text style={styles.boldText}>Gauge ID:</Text>
                                <Text style={styles.text}>{gauge.gauge_id}</Text>
                            </View>
                            <View style={styles.textRow}>
                                <Text style={styles.boldText}>Model:</Text>
                                <Text style={styles.text}>{gauge.model}</Text>
                            </View>
                            <View style={styles.textRow}>
                                <Text style={styles.boldText}>Manufacturer:</Text>
                                <Text style={styles.text}>{gauge.manufacturer}</Text>
                            </View>
                            <View style={styles.textRow}>
                                <Text style={styles.boldText}>Status: </Text>
                                <Text style={styles.text}>{gauge.status === 0 ? 'Active' : 'Inactive'}</Text>
                            </View>
                        </View>
                    </View>
    
                    {chartImages.displacement && (
                        <View style={styles.section}>
                            <Text style={styles.title}>Displacement Chart</Text>
                            <Image style={styles.chartImage} src={chartImages.displacement} />
                        </View>
                    )}
                    {chartImages.weather && (
                        <View style={styles.section}>
                            <Text style={styles.title}>Weather Chart</Text>
                            <Image style={styles.chartImage} src={chartImages.weather} />
                        </View>
                    )}
                </Page>
    
                {/* Second Page with Data Table Section */}
                <Page style={styles.page}>
                    {/* Data Table Section */}
                    <View style={styles.section}>
                        <Text style={styles.title}>Data Received</Text>
                        {/* Table Header */}
                        <View style={styles.tableRow}>
                            <Text style={[styles.tableCol, styles.tableHeader]}>Date</Text>
                            <Text style={[styles.tableCol, styles.tableHeader]}>Temperature (°C)</Text>
                            <Text style={[styles.tableCol, styles.tableHeader]}>Humidity (%)</Text>
                            <Text style={[styles.tableCol, styles.tableHeader]}>Displacement (mm)</Text>
                            <Text style={[styles.tableCol, styles.tableHeader]}>Comments</Text>
                        </View>
                        {/* Table Rows */}
                        {gaugeData.map((data, index) => (
                            <View key={index} style={styles.tableRow}>
                                <Text style={styles.tableCol}>{new Date(data.datetime).toLocaleDateString()}</Text>
                                <Text style={styles.tableCol}>{data.temperature} °C</Text>
                                <Text style={styles.tableCol}>{data.humidity} %</Text>
                                <Text style={styles.tableCol}>{data.value} mm</Text>
                                <Text style={styles.tableCol}>{data.comments} mm</Text>
                            </View>
                        ))}
                    </View>
                </Page>
            </Document>
        );
    
        return PdfDocument;
    };
    

    const exportToXlsx = () => {
        const worksheet = XLSX.utils.json_to_sheet(gaugeData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Gauge Data");

        // Set the file name and export
        XLSX.writeFile(workbook, `gauge_data_${gaugeId}.xlsx`);
    };
    return (
        <div className="grid p-fluid">
            {loading ? (
                <div>Loading...</div>
            ) : (
                <>
                    <div className="col-12">
                        <div className="card">
                            <div className="flex justify-content-between">
                                <h5>Gauge Detail Report</h5>
                                <div>
                                    <PDFDownloadLink
                                        document={exportToPdf()} // Call exportToPdf to get the document
                                        fileName="gauge_detail_report.pdf"
                                    >
                                        {({ loading }) =>
                                            loading ? (
                                                <Button label="Loading..." icon="pi pi-spinner" className="p-button-warning" />
                                            ) : (
                                                <Button label="Export PDF" icon="pi pi-file-pdf" className="p-button-danger mr-2" />
                                            )
                                        }
                                    </PDFDownloadLink>
                                    <Button label="Export XLSX" icon="pi pi-file-excel" className="p-button-success" onClick={exportToXlsx} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Project Details Card */}
                    <div className="col-12 lg:col-4">
                        <Card title="Project Details">
                            <p><strong>Name:</strong> {project.name}</p>
                            <p><strong>Address:</strong> {project.address}</p>
                            <p><strong>Field Engineer:</strong> {project.field_engineer}</p>
                            <p><strong>Status:</strong> {project.status === 0 ? 'Open' : 'Closed'}</p>
                        </Card>
                    </div>

                    {/* Device Details Card */}
                    <div className="col-12 lg:col-4">
                        <Card title="Device Details">
                            <p><strong>Device ID:</strong> {device.device_id}</p>
                            <p><strong>Model:</strong> {device.model}</p>
                            <p><strong>Serial:</strong> {device.serial}</p>
                            <p><strong>Status:</strong> {device.status}</p>
                        </Card>
                    </div>

                    {/* Gauge Details Card */}
                    <div className="col-12 lg:col-4">
                        <Card title="Gauge Details">
                            <p><strong>Gauge ID:</strong> {gauge.gauge_id}</p>
                            <p><strong>Model:</strong> {gauge.model}</p>
                            <p><strong>Manufacturer:</strong> {gauge.manufacturer}</p>
                            <p><strong>Status:</strong> {gauge.status}</p>
                        </Card>
                    </div>

                   {/* Chart Section */}
                   
                    <div className="col-12 lg:col-6">
                        <div className="card">
                            <h5>Displacement Chart</h5>
                            <Chart id="displacement-chart" ref={displacementChartRef} type="line" data={chartData.displacement} options={getLineChartOptions()} />
                        </div>
                    </div>
                    <div className="col-12 lg:col-6">
                        <div className="card">
                            <h5>Weather Chart</h5>
                            <Chart id="weather-chart" ref={weatherChartRef} type="line" data={chartData.weather} options={getLineChartOptions()} />
                        </div>
                    </div>
                    {/* Data Table for Gauge Data */}
                    <div className="col-12">
                        <div className="card">
                            <h5>Data Received</h5>
                            <DataTable value={gaugeData} paginator rows={10}>
                                <Column field="datetime" header="Date" />
                                <Column field="temperature" header="Temperature (°C)" />
                                <Column field="humidity" header="Humidity (%)" /> 
                                <Column field="comments" header="Comments" />
                            </DataTable>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default GaugeDetail;
